import React, { Fragment, useState } from "react";

import clsx from "clsx";
import { Link, NavLink, Outlet } from "react-router-dom";

import {
  ApplicationIcon,
  CivixIcon,
  HelpIcon,
  HomeIcon,
  ProfileIcon,
} from "components/icons";
import { LogoutModal, Profile } from "components/ui";

const links = [
  { name: "Home", href: "/dashboard", icon: HomeIcon },
  { name: "Application", href: "/applications", icon: ApplicationIcon },
  { name: "Help", href: "/help", icon: HelpIcon },
  { name: "Profile", href: "/profile", icon: ProfileIcon },
];

const PrimaryNavbar = () => {
  const auth = JSON.parse(localStorage.getItem("cvx-u"));

  const [isLogoutModal, setIsLogoutModal] = useState(false);

  return (
    <Fragment>
      <nav className="sticky top-0 z-40 hidden h-20 w-full items-center justify-between overflow-hidden bg-white px-4 shadow-[0_4px_13px_rgba(0,0,0,0.04)] sm:px-6 lg:flex lg:bg-white lg:px-8">
        <Link to="/" title="Civix">
          <CivixIcon className="ml-4 w-28 sm:ml-0" />
        </Link>
        <div className="-mr-4 flex h-full items-center bg-japanese-indigo px-6 sm:-mr-6 lg:-mr-8 lg:px-10">
          {auth && (
            <Profile firstName={auth?.firstName} lastName={auth?.lastName} />
          )}

          {auth && (
            <button
              type="button"
              className="ml-3 flex items-center text-white"
              onClick={() => setIsLogoutModal(true)}
            >
              <span className="material-icons">logout</span>
            </button>
          )}
        </div>
      </nav>
      <nav className="fixed bottom-0 z-40 block h-20 w-full bg-white shadow-[0_0px_16px_rgba(0,0,0,0.05)] lg:hidden">
        <ul className="flex items-center justify-around py-4">
          {links.map((item, i) => {
            return (
              <li key={i}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    clsx(
                      isActive ? "text-azure-radiance" : "text-cadet-blue",
                      "flex select-none flex-col items-center text-sm"
                    )
                  }
                >
                  <item.icon className="mb-2 h-5 w-5" />
                  {item.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <Outlet />

      {isLogoutModal && (
        <LogoutModal
          isLogoutModal={isLogoutModal}
          setIsLogoutModal={setIsLogoutModal}
        />
      )}
    </Fragment>
  );
};

export default PrimaryNavbar;
