import React from "react";

const GearIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.556 8.25992L17.2619 7.69574C17.124 7.26086 16.9466 6.83914 16.7326 6.43574C17.077 5.86238 17.8009 4.65562 17.8009 4.65562C17.9394 4.42504 17.9027 4.12977 17.7128 3.9398L16.0557 2.2827C15.8657 2.09215 15.571 2.05555 15.3398 2.19457L13.5568 3.26289C13.1557 3.05059 12.7363 2.87492 12.3043 2.73816L11.7401 0.444063C11.6748 0.183125 11.4408 0 11.1719 0H8.82812C8.55918 0 8.32457 0.183125 8.25992 0.444023C8.25992 0.444023 7.8691 2.0452 7.69629 2.73758C7.23566 2.88348 6.79051 3.07289 6.36648 3.30406L4.5177 2.19457C4.28652 2.05723 3.99242 2.09273 3.80188 2.2827L2.14477 3.9398C1.9548 4.12977 1.91816 4.42504 2.05664 4.65562L3.19992 6.56508C3.01566 6.92844 2.86117 7.30664 2.73816 7.69402L0.444063 8.25992C0.183125 8.32516 0 8.55918 0 8.82812V11.1719C0 11.4408 0.183125 11.6754 0.444023 11.7401L2.73871 12.3043C2.87777 12.7454 3.05855 13.174 3.27773 13.5854L2.25465 15.2906C2.11617 15.5212 2.15281 15.8164 2.34277 16.0064L3.99988 17.6635C4.1893 17.8529 4.48453 17.8889 4.7157 17.7516C4.7157 17.7516 5.86297 17.0639 6.42488 16.7274C6.83172 16.9443 7.25742 17.1234 7.69633 17.2624L8.25996 19.5559C8.32457 19.8169 8.55918 20 8.82812 20H11.1719C11.4408 20 11.6748 19.8169 11.7401 19.556L12.3037 17.2624C12.7552 17.1194 13.19 16.9345 13.6038 16.7102C14.1811 17.0564 15.3398 17.7516 15.3398 17.7516C15.5704 17.8895 15.8657 17.8541 16.0556 17.6635L17.7127 16.0064C17.9027 15.8164 17.9393 15.5212 17.8009 15.2906L16.7474 13.5356C16.9551 13.1414 17.1274 12.7288 17.2618 12.3043L19.5559 11.7401C19.8169 11.6748 20 11.4408 20 11.1719V8.82812C20 8.55918 19.8169 8.32516 19.556 8.25992ZM10 14.1016C7.73863 14.1016 5.89844 12.2614 5.89844 10C5.89844 7.73863 7.73863 5.89844 10 5.89844C12.2614 5.89844 14.1016 7.73863 14.1016 10C14.1016 12.2614 12.2614 14.1016 10 14.1016Z" />
    </svg>
  );
};

export default GearIcon;
