// AUTH
export const SIGNUP = "SIGNUP";
export const SIGNIN = "SIGNIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGOUT = "LOGOUT";

export const CONTACT_US = "CONTACT_US";

// DASHBOARD
export const PARK_DASHBOARD_COUNT = "PARK_DASHBOARD_COUNT";
export const APPLICATION_DASHBOARD_COUNT = "APPLICATION_DASHBOARD_COUNT";
export const APPLICATION_DASHBOARD_ANALYTICS =
  "APPLICATION_DASHBOARD_ANALYTICS";
export const PARK_DASHBOARD_ANALYTICS = "PARK_DASHBOARD_ANALYTICS";

//CITY MARKETING
export const CREATE_CITY_MARKETING = "CREATE_CITY_MARKETING";
export const RETRIEVE_PAYMENT_SESSION = "RETRIEVE_PAYMENT_SESSION";
export const CITY_MARKETING_PAYMENT = "CITY_MARKETING_PAYMENT";
export const RETRIEVE_MARKETING_LIST = "RETRIEVE_MARKETING_LIST";
export const RETRIEVE_PAYMENT_LINK = "RETRIEVE_PAYMENT_LINK";

// USER
export const VERIFY_USER = "VERIFY_USER";
export const RETRIVE_USER_APPLICATION = "RETRIVE_USER_APPLICATION";
export const STORE_USER_TOKEN = "STORE_USER_TOKEN";
export const USER_SUBSCRIPTION_CHECK = "USER_SUBSCRIPTION_CHECK";

export const RETRIEVE_USER_BY_ID = "RETRIEVE_USER_BY_ID";
export const RETRIEVE_USER = "RETRIEVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const RENEWTOKEN = "RENEWTOKEN";

// FAMILY MEMBER
export const CREATE_FAMILY_MEMBER = "CREATE_FAMILY_MEMBER";
export const RETRIEVE_FAMILY_MEMBER = "RETRIEVE_FAMILY_MEMBER";
export const UPDATE_FAMILY_MEMBER = "UPDATE_FAMILY_MEMBER";
export const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER";
export const VERIFY_FAMILY_MEMBER = "VERIFY_FAMILY_MEMBER";
export const APPROVE_FAMILY_MEMBER = "APPROVE_FAMILY_MEMBER";

// CITY
export const CREATE_CITY = "CREATE_CITY";
export const RETRIEVE_CITY = "RETRIEVE_CITY";
export const RETRIEVE_CITIES = "RETRIEVE_CITIES";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";

// STATE
export const CREATE_STATE = "CREATE_STATE";
export const RETRIEVE_STATE = "RETRIEVE_STATE";
export const RETRIEVE_STATES = "RETRIEVE_STATES";
export const UPDATE_STATE = "UPDATE_STATE";
export const DELETE_STATE = "DELETE_STATE";

// COUNTRY
export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const RETRIEVE_COUNTRY = "RETRIEVE_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";

// DEPARTMENT
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const RETRIEVE_DEPARTMENT = "RETRIEVE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

// CONFIGS
export const CREATE_CONFIGS = "CREATE_CONFIGS";
export const RETRIEVE_CONFIGS = "RETRIEVE_CONFIGS";
export const UPDATE_CONFIGS = "UPDATE_CONFIGS";
export const DELETE_CONFIGS = "DELETE_CONFIGS";

// ZONAL_DEPARTMENTS
export const CREATE_ZONAL_DEPARTMENT = "CREATE_ZONAL_DEPARTMENT";
export const RETRIEVE_ZONAL_DEPARTMENT = "RETRIEVE_ZONAL_DEPARTMENT";
export const RETRIEVE_ZONAL_DEPARTMENT_BY_ID =
  "RETRIEVE_ZONAL_DEPARTMENT_BY_ID";
export const UPDATE_ZONAL_DEPARTMENT = "UPDATE_ZONAL_DEPARTMENT";
export const DELETE_ZONAL_DEPARTMENT = "DELETE_ZONAL_DEPARTMENT";

// COUNCIL
export const CREATE_COUNCIL = "CREATE_COUNCIL";
export const RETRIEVE_COUNCIL = "RETRIEVE_COUNCIL";
export const UPDATE_COUNCIL = "UPDATE_COUNCIL";
export const DELETE_COUNCIL = "DELETE_COUNCIL";

// DEPARTMENT_USER
export const CREATE_DEPARTMENT_USER = "CREATE_DEPARTMENT_USER";
export const RETRIEVE_DEPARTMENT_USER = "RETRIEVE_DEPARTMENT_USER";
export const RETRIEVE_DEPARTMENT_USER_BY_ID = "RETRIEVE_DEPARTMENT_USER_BY_ID";
export const UPDATE_DEPARTMENT_USER = "UPDATE_DEPARTMENT_USER";
export const DELETE_DEPARTMENT_USER = "DELETE_DEPARTMENT_USER";

// FORM_ELEMENT
export const RETRIEVE_FORM_ELEMENTS = "RETRIEVE_FORM_ELEMENTS";
export const RETRIEVE_FORM_ELEMENT = "RETRIEVE_FORM_ELEMENT";

// FORM_GROUP
export const CREATE_FORM_GROUP = "CREATE_FORM_GROUP";
export const RETRIEVE_FORM_GROUPS = "RETRIEVE_FORM_GROUPS";
export const RETRIEVE_FORM_GROUP = "RETRIEVE_FORM_GROUP";
export const UPDATE_FORM_GROUP = "UPDATE_FORM_GROUP";
export const DELETE_FORM_GROUP = "DELETE_FORM_GROUP";

// APPLICATION
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const USER_DRAFT_APPLICATION = "USER_DRAFT_APPLICATION";
export const RETRIEVE_APPLICATIONS = "RETRIEVE_APPLICATIONS";
export const RETRIEVE_APPLICATION = "RETRIEVE_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const APPLY_APPLICATION = "APPLY_APPLICATION";
export const USER_APPLICATION_UPDATE = "USER_APPLICATION_UPDATE";
export const SEND_FOR_APPROVAL = "SEND_FOR_APPROVAL";
export const MAKE_APPROVE = "MAKE_APPROVE";
export const APPROVAL_LOGS = "APPROVAL_LOGS";
export const FETCH_APPLICATION = "FETCH_APPLICATION";
export const TRANSFER_APPLICATION_TO_HOD = "TRANSFER_APPLICATION_TO_HOD";
export const TRANSFER_APPLICATION_TO_STAFF = "TRANSFER_APPLICATION_TO_STAFF";
export const APPLICATION_STATUS_UPDATE = "APPLICATION_STATUS_UPDATE";
export const DELETE_APPROVE_REQUEST = "DELETE_APPROVE_REQUEST";
export const USER_PAYMENT_APPLICATION = "USER_PAYMENT_APPLICATION";
export const USER_PAYMENT_INVOICE = "USER_PAYMENT_INVOICE";

// FILE UPLOAD
export const CREATE_SIGNED_URL = "CREATE_SIGNED_URL";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const RETRIEVE_IMAGE = "RETRIEVE_IMAGE";

//SUBSCRIPTION
export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const CREATE_STRIPE_CUSTOMER = "CREATE_STRIPE_CUSTOMER";
export const BUY_SUBSCRIPTION = "BUY_SUBSCRIPTION";

//PARK
export const GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS";
export const RETRIEVE_PROGRAM = "RETRIEVE_PROGRAM";
export const RETRIEVE_REGISTRATION = "RETRIEVE_REGISTRATION";
export const USER_PARK_PAYMENT_INTENT = "USER_PARK_PAYMENT_INTENT";
export const USER_PARK_REGISTER = "USER_PARK_REGISTER";
export const RETRIEVE_USER_PARK_REGISTER_COST =
  "RETRIEVE_USER_PARK_REGISTER_COST";

//PARK LOCATION
export const CREATE_PARK_LOCATION = "CREATE_PARK_LOCATION";
export const GET_ALL_PARK_LOCATION = "GET_ALL_PARK_LOCATION";
export const GET_ONE_PARK_LOCATION = "GET_ONE_PARK_LOCATION";
export const UPDATE_PARK_LOCATION = "UPDATE_PARK_LOCATION";
export const REMOVE_PARK_LOCATION = "REMOVE_PARK_LOCATION";

//PARK CATEGORY
export const CREATE_PARK_CATEGORY = "CREATE_PARK_CATEGORY";
export const GET_ALL_PARK_CATEGORY = "GET_ALL_PARK_CATEGORY";
// export const GET_ONE_PARK_CATEGORY = "GET_ONE_PARK_CATEGORY";
export const UPDATE_PARK_CATEGORY = "UPDATE_PARK_CATEGORY";
export const REMOVE_PARK_CATEGORY = "REMOVE_PARK_CATEGORY";

// PARK SUBCATEGORIES
export const CREATE_PARK_SUBCATEGORIES = "RETRIEVE_SUBCATEGORIES";
export const RETRIEVE_SUBCATEGORIES = "RETRIEVE_SUBCATEGORIES";
export const UPDATE_PARK_SUBCATEGORIES = "RETRIEVE_SUBCATEGORIES";
export const REMOVE_PARK_SUBCATEGORIES = "RETRIEVE_SUBCATEGORIES";

//PARK SEASON
// export const CREATE_PARK_SEASON = "CREATE_PARK_SEASON";
export const GET_ALL_PARK_SEASON = "GET_ALL_PARK_SEASON";
// export const GET_ONE_PARK_SEASON = "GET_ONE_PARK_SEASON";
// export const UPDATE_PARK_SEASON = "UPDATE_PARK_SEASON";
// export const REMOVE_PARK_SEASON = "REMOVE_PARK_SEASON";

//PARK PROGRAM
export const CREATE_PARK_PROGRAM = "CREATE_PARK_PROGRAM";
export const GET_ALL_PARK_PROGRAM = "GET_ALL_PARK_PROGRAM";
export const GET_ONE_PARK_PROGRAM = "GET_ONE_PARK_PROGRAM";
export const UPDATE_PARK_PROGRAM = "UPDATE_PARK_PROGRAM";
export const REMOVE_PARK_PROGRAM = "REMOVE_PARK_PROGRAM";

//PARK PROGRAM APPROVAL
export const GET_ALL_PROGRAM_APPROVAL = "GET_ALL_PROGRAM_APPROVAL";
export const HOD_APPROVE_APPLICATION = "HOD_APPROVE_APPLICATION";
export const REMOVE_PARK_PROGRAM_APPROVAL = "REMOVE_PARK_PROGRAM_APPROVAL";
export const SEND_TO_HOD_FOR_APPROVAL = "SEND_TO_HOD_FOR_APPROVAL";
