import React, { useState } from "react";

import clsx from "clsx";
import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";

import { CivixIcon } from "components/icons";
import { LogoutModal, Profile } from "components/ui";

const CustomNavLink = ({ to, title }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink
      to={to}
      className={clsx(
        match
          ? "border-b-2 border-azure text-azure"
          : "text-japanese-indigo/60",
        "pb-1 text-sm leading-6 tracking-wide"
      )}
    >
      {title}
    </NavLink>
  );
};

const Navbar = () => {
  const auth = JSON.parse(localStorage.getItem("cvx-u"));

  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const navigation = [
    { name: "Home", href: "/", new: false },
    {
      name: "Dashboard",
      href: "/dashboard",
      new: false,
      auth: auth ? true : false,
    },
    { name: "Our Cities", href: "/clients", new: false },
    { name: "Are you a city?", href: "/city", new: false },
    // { name: "Why choose Civix?", href: "/help", new: false },
  ];

  return (
    <>
      <nav className="w-full bg-white">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex h-20 items-center justify-between">
            <Link to="/" title="Civix">
              <CivixIcon className="ml-4 w-28 sm:ml-0" />
            </Link>
            <ul className="hidden space-x-8 lg:flex xl:space-x-14">
              {navigation
                .filter(function (nav) {
                  return nav.auth === true || nav.auth === undefined;
                })
                .map((item, i) => {
                  return (
                    <li key={i}>
                      <CustomNavLink to={item.href} title={item.name} />
                      {item.new && (
                        <Link to={item.href}>
                          <span className="ml-2 rounded-full bg-link-water px-2 py-0.5 text-xs font-light text-azure-radiance">
                            New
                          </span>
                        </Link>
                      )}
                    </li>
                  );
                })}
            </ul>
            <div className="flex h-full items-center bg-japanese-indigo px-6 lg:px-10">
              <div className="font-light leading-6 text-white">
                {auth !== null && (
                  <Profile
                    firstName={auth?.firstName}
                    lastName={auth?.lastName}
                  />
                )}
                {auth === null && (
                  <div className="text-sm">
                    <Link to="/signin" title="Sign in">
                      Sign in
                    </Link>
                    /{" "}
                    <Link to="/signup" title="Sign up">
                      Sign up
                    </Link>
                  </div>
                )}
              </div>
              {auth !== null ? (
                <button
                  type="button"
                  className="ml-3 flex items-center text-white"
                  onClick={() => setIsLogoutModal(true)}
                >
                  <span className="material-icons">logout</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </nav>

      {isLogoutModal && (
        <LogoutModal
          isLogoutModal={isLogoutModal}
          setIsLogoutModal={setIsLogoutModal}
        />
      )}
    </>
  );
};

export default Navbar;
