import React from "react";

const UnderReviewIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 9.00036V12.0004M12 15.0004H12.01M20 12.0004C20 16.4615 14.54 19.6941 12.6414 20.6833C12.4361 20.7903 12.3334 20.8438 12.191 20.8715C12.08 20.8931 11.92 20.8931 11.809 20.8715C11.6666 20.8438 11.5639 20.7903 11.3586 20.6833C9.45996 19.6941 4 16.4615 4 12.0004V8.21796C4 7.41845 4 7.01869 4.13076 6.67506C4.24627 6.3715 4.43398 6.10064 4.67766 5.88589C4.9535 5.6428 5.3278 5.50243 6.0764 5.22171L11.4382 3.21103C11.6461 3.13307 11.75 3.09409 11.857 3.07864C11.9518 3.06493 12.0482 3.06493 12.143 3.07864C12.25 3.09409 12.3539 3.13307 12.5618 3.21103L17.9236 5.22171C18.6722 5.50243 19.0465 5.6428 19.3223 5.88589C19.566 6.10064 19.7537 6.3715 19.8692 6.67506C20 7.01869 20 7.41845 20 8.21796V12.0004Z"
        stroke="#F49A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnderReviewIcon;
