import {
  RETRIEVE_ZONAL_DEPARTMENT,
  RETRIEVE_ZONAL_DEPARTMENT_BY_ID,
} from "actions/types";

const initialState = {
  data: [],
};

const zonalDepartmentReducer = (zonalDepartments = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ZONAL_DEPARTMENT:
      return payload;

    case RETRIEVE_ZONAL_DEPARTMENT_BY_ID:
      return payload;

    default:
      return zonalDepartments;
  }
};

export default zonalDepartmentReducer;
