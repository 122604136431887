import React from "react";

const HomeIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 6.59346V17.6557C19.9994 18.2449 19.7651 18.8098 19.3485 19.2264C18.9319 19.643 18.367 19.8773 17.7778 19.8779H14.0741C13.6812 19.8779 13.3043 19.7218 13.0265 19.444C12.7487 19.1661 12.5926 18.7893 12.5926 18.3964V14.3223C12.5926 14.1259 12.5146 13.9375 12.3756 13.7986C12.2367 13.6596 12.0483 13.5816 11.8519 13.5816H8.14815C7.95169 13.5816 7.76328 13.6596 7.62437 13.7986C7.48545 13.9375 7.40741 14.1259 7.40741 14.3223V18.3964C7.40741 18.7893 7.25132 19.1661 6.97349 19.444C6.69566 19.7218 6.31884 19.8779 5.92593 19.8779H2.22222C1.63303 19.8773 1.06814 19.643 0.651524 19.2264C0.234904 18.8098 0.000588307 18.2449 0 17.6557V6.59346C0.00055429 6.33763 0.0670316 6.08627 0.193018 5.86362C0.319004 5.64097 0.500247 5.45455 0.719259 5.32235L9.23778 0.211236C9.46807 0.0730158 9.7316 0 10.0002 0C10.2688 0 10.5323 0.0730158 10.7626 0.211236L19.2811 5.32235C19.5001 5.4546 19.6812 5.64104 19.8071 5.86369C19.9331 6.08633 19.9995 6.33767 20 6.59346Z" />
    </svg>
  );
};

export default HomeIcon;
