import React from "react";

import Tooltip from "../Tooltip";

const Profile = ({ firstName, lastName }) => {
  const firstChar = firstName.charAt(0);
  const lastChar = lastName.charAt(0);

  return (
    <Tooltip content={`${firstName} ${lastName}`}>
      <div className="text-lg font-medium uppercase tracking-wider text-white">{`${firstChar}${lastChar}`}</div>
    </Tooltip>
  );
};

export default Profile;
