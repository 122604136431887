import React from "react";

const CivixIcon = ({ ...props }) => {
  return (
    <svg
      width="141"
      height="48"
      viewBox="0 0 141 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_245_2512)">
        <path
          d="M0 28.7545C0 17.3184 8.79225 9.50391 19.852 9.50391C28.4746 9.50391 33.2923 13.9701 35.9608 18.5981L29.0401 21.9465C27.4514 18.9319 24.0527 16.5345 19.852 16.5345C13.2706 16.5345 8.28323 21.7239 8.28323 28.7545C8.28323 35.785 13.2706 40.9694 19.852 40.9694C24.0476 40.9694 27.4514 38.6276 29.0401 35.5574L35.9608 38.8502C33.2357 43.4833 28.4746 48 19.852 48C8.79225 48 0 40.1905 0 28.7545Z"
          fill="#263C53"
        />
        <path
          d="M40.7734 10.1157H48.8304V47.3271H40.7734V10.1157Z"
          fill="#263C53"
        />
        <path
          d="M89.6153 0H99.1325L64.6011 47.3323H56.3281L89.6153 0Z"
          fill="url(#paint0_linear_245_2512)"
        />
        <path d="M99.2276 10.1162V47.3276H91.1758V21.1577" fill="#263C53" />
        <path
          d="M56.3223 10.1162H64.3793V47.3276H56.3223V10.1162Z"
          fill="#263C53"
        />
        <path
          d="M121.883 33.7469L112.356 47.3579H102.771L116.495 28.2793L103.62 10.1465H113.204L121.878 22.8672L130.444 10.1465H140.141L127.272 28.2236L140.995 47.3579H131.354L121.883 33.7469Z"
          fill="#0C7DFB"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_245_2512"
          x1="95.2174"
          y1="-1.17683"
          x2="60.1625"
          y2="49.3062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#263C53" />
          <stop offset="0.1509" stopColor="#253A51" />
          <stop offset="0.3085" stopColor="#21364C" />
          <stop offset="0.4691" stopColor="#192E43" />
          <stop offset="0.6318" stopColor="#0D2336" />
          <stop offset="0.7962" stopColor="#001023" />
          <stop offset="0.9597" stopColor="#000005" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_245_2512">
          <rect width="141" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CivixIcon;
