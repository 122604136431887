import React from "react";

const FormsIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8906 5.85938C10.1368 5.85938 9.52344 5.24605 9.52344 4.49219V0H2.53125C1.3466 0 0.382812 0.963789 0.382812 2.14844V17.8516C0.382812 19.0362 1.3466 20 2.53125 20H13.4688C14.6534 20 15.6172 19.0362 15.6172 17.8516V5.85938H10.8906ZM3.58594 14.0625H6.42656C6.75016 14.0625 7.0125 14.3248 7.0125 14.6484C7.0125 14.972 6.75016 15.2344 6.42656 15.2344H3.58594C3.26234 15.2344 3 14.972 3 14.6484C3 14.3248 3.26234 14.0625 3.58594 14.0625ZM3 11.5234C3 11.1998 3.26234 10.9375 3.58594 10.9375H12.1797C12.5033 10.9375 12.7656 11.1998 12.7656 11.5234C12.7656 11.847 12.5033 12.1094 12.1797 12.1094H3.58594C3.26234 12.1094 3 11.847 3 11.5234ZM12.1797 7.8125C12.5033 7.8125 12.7656 8.07484 12.7656 8.39844C12.7656 8.72203 12.5033 8.98438 12.1797 8.98438H3.58594C3.26234 8.98438 3 8.72203 3 8.39844C3 8.07484 3.26234 7.8125 3.58594 7.8125H12.1797Z" />
      <path d="M10.6953 4.4921C10.6953 4.59979 10.7829 4.68741 10.8906 4.68741H15.356C15.2488 4.48952 15.1109 4.30776 14.9453 4.15101L11.1788 0.587646C11.0326 0.449365 10.8698 0.334014 10.6954 0.24292V4.4921H10.6953Z" />
    </svg>
  );
};

export default FormsIcon;
