import React from "react";

const GoogleSheetsIcon = ({ ...props }) => {
  return (
    <svg
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71208 0H16.0837L25.7158 10.049V29.5721C25.7158 32.0161 23.7319 34 21.2879 34H4.71208C2.26811 34 0.28418 32.0161 0.28418 29.5721V4.4279C0.28418 1.98393 2.26811 0 4.71208 0V0Z"
        fill="#0F9252"
      />
      <path
        opacity="0.302"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0693 0V9.96278H25.7158L16.0693 0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77441 24.9429H12.2886V26.6537H7.77441V24.9429ZM13.6975 16.2021H18.226V17.8986H13.6975V16.2021ZM7.77441 16.2021H12.2886V17.8986H7.77441V16.2021ZM13.6975 19.0774H18.226V20.7882H13.6975V19.0774ZM7.77441 19.0774H12.2886V20.7882H7.77441V19.0774ZM13.6975 22.0677H18.226V23.7785H13.6975V22.0677ZM7.77441 22.0677H12.2886V23.7785H7.77441V22.0677ZM13.6975 24.9429H18.226V26.6537H13.6975V24.9429Z"
        fill="white"
      />
    </svg>
  );
};

export default GoogleSheetsIcon;
