import React from "react";

const AddressIcon = ({ ...props }) => {
  return (
    <svg
      width="17"
      height="34"
      viewBox="0 0 17 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.27714 17.4773L7.25098 18.5571V31.8112C7.25098 32.5173 7.46408 33.2013 7.8547 33.7774C8.0561 34.0745 8.49818 34.0745 8.69965 33.7774C9.09027 33.2013 9.30337 32.5173 9.30337 31.8112V18.5571L8.27714 17.4773Z"
        fill="#ACD5DF"
      />
      <path
        d="M7.25098 14.5032V18.5571C7.58858 18.5905 7.93084 18.5571 8.27714 18.5571C8.62344 18.5571 8.9657 18.5905 9.3033 18.5571V14.5032H7.25098Z"
        fill="#ACD5DF"
      />
      <path
        d="M8.27734 0V16.5554C12.849 16.5554 16.5551 12.8493 16.5551 8.27772C16.5551 3.70609 12.849 0 8.27734 0Z"
        fill="#1062BD"
      />
      <path
        d="M14.5031 8.27772C14.5031 3.70609 11.7159 0 8.27772 0C3.70609 0 0 3.70609 0 8.27772C0 12.8493 3.70609 16.5554 8.27772 16.5554C11.7159 16.5554 14.5031 12.8493 14.5031 8.27772Z"
        fill="#0C7DFB"
      />
    </svg>
  );
};

export default AddressIcon;
