import React from "react";

const InstagramIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96875 0C1.86418 0 0.96875 0.89543 0.96875 2V44C0.96875 45.1046 1.86418 46 2.96875 46H44.9688C46.0733 46 46.9688 45.1046 46.9688 44V2C46.9688 0.895431 46.0733 0 44.9688 0H2.96875ZM16.7815 8.625H31.1561C35.1088 8.625 38.3434 11.8596 38.3434 15.8123V30.1877C38.3434 34.1393 35.1088 37.375 31.1561 37.375H16.7815C12.8288 37.375 9.59415 34.1393 9.59415 30.1877V15.8123C9.59415 11.8596 12.8288 8.625 16.7815 8.625ZM31.1561 34.9791C33.7989 34.9791 35.9476 32.829 35.9476 30.1877V15.8123C35.9476 13.1703 33.7989 11.0209 31.1561 11.0209H16.7815C14.1398 11.0209 11.99 13.1703 11.99 15.8123V30.1877C11.99 32.829 14.1398 34.9791 16.7815 34.9791H31.1561ZM31.7562 17.0101C32.7486 17.0101 33.553 16.2056 33.553 15.2133C33.553 14.2209 32.7486 13.4164 31.7562 13.4164C30.7638 13.4164 29.9594 14.2209 29.9594 15.2133C29.9594 16.2056 30.7638 17.0101 31.7562 17.0101ZM16.7814 22.9997C16.7814 19.0297 19.9985 15.8123 23.9688 15.8123C27.9379 15.8123 31.1561 19.0297 31.1561 22.9997C31.1561 26.9681 27.9379 30.1877 23.9688 30.1877C19.9985 30.1877 16.7814 26.9681 16.7814 22.9997ZM19.1773 22.9997C19.1773 25.6464 21.3227 27.7918 23.9688 27.7918C26.6148 27.7918 28.7602 25.6464 28.7602 22.9997C28.7602 20.3529 26.6148 18.2082 23.9688 18.2082C21.3227 18.2082 19.1773 20.3529 19.1773 22.9997Z"
        fill="#DFE3E5"
      />
    </svg>
  );
};

export default InstagramIcon;
