import React from "react";

const ProfileIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 8.94734C12.4708 8.94734 14.4737 6.94441 14.4737 4.47367C14.4737 2.00293 12.4708 0 10 0C7.5293 0 5.52637 2.00293 5.52637 4.47367C5.52637 6.94441 7.5293 8.94734 10 8.94734Z" />
      <path d="M10.0002 10C5.64008 10 2.10547 13.5346 2.10547 17.8947C2.10547 19.0574 3.04805 20 4.21074 20H15.7897C16.9524 20 17.895 19.0574 17.895 17.8947C17.8949 13.5346 14.3603 10 10.0002 10Z" />
    </svg>
  );
};

export default ProfileIcon;
