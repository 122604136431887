import React, { Fragment, useContext, useState } from "react";

import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CivixSecondaryIcon,
  HamburgerIcon,
} from "components/icons";

import { AppContext } from "context/AppContext";

import useRole from "hooks/useRole";

import { ROLE } from "utils/roles";

const Sidebar = () => {
  const { showSidebar, setShowSidebar } = useContext(AppContext);
  const [navigation] = useState([
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: "dashboard",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.COUNCIL,
        ROLE.DEPARTMENT_ADMIN,
        ROLE.DEPARTMENT_USER,
        ROLE.USER,
        ROLE.PARK_ADMIN,
        ROLE.PARK_USER,
      ]),
    },
    {
      name: "Users",
      href: "/users",
      icon: "group",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Users",
      href: "/department-users",
      icon: "group",
      permission: useRole([ROLE.DEPARTMENT_ADMIN]),
    },
    {
      name: "Applications",
      href: "/applications",
      icon: "folder",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.DEPARTMENT_ADMIN,
        ROLE.DEPARTMENT_USER,
        ROLE.USER,
      ]),
    },
    {
      name: "Programs",
      href: "/programs",
      icon: "event",
      permission: useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER]),
    },
    {
      name: "Program view",
      href: "/program-view",
      icon: "event",
      permission: useRole([]),
    },
    {
      name: "Location",
      href: "/location",
      icon: "location_on",
      permission: useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER]),
    },
    {
      name: "All Program Forms",
      href: "/program-forms",
      icon: "description",
      permission: useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER]),
    },
    {
      name: "Approved Program",
      href: "/approved-program",
      icon: "event_available",
      permission: useRole([ROLE.PARK_ADMIN]),
    },
    {
      name: "Departments",
      href: "/departments",
      icon: "domain_add",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Zonal Departments",
      href: "/zonal-departments",
      icon: "domain",
      permission: useRole([ROLE.ADMIN, ROLE.COUNCIL]),
    },
    {
      name: "Council",
      href: "/council",
      icon: "location_city",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Transfer Application",
      href: "/transfer-application",
      icon: "swap_horiz",
      permission: useRole([ROLE.DEPARTMENT_ADMIN]),
    },
    {
      name: "City Marketing",
      href: "/city-list",
      icon: "location_city",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Calendar",
      href: "/calendar",
      icon: "calendar_today",
      permission: useRole([]),
    },
    {
      name: "Map",
      href: "/map",
      icon: "map",
      permission: useRole([]),
    },
    {
      name: "Cities",
      href: "/cities",
      icon: "location_city",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "States",
      href: "/states",
      icon: "location_city",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Countries",
      href: "/countries",
      icon: "public",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Park Categories",
      href: "/park-category",
      icon: "category",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Park SubCategories",
      href: "/park-sub-category",
      icon: "category",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Analytics",
      href: "/analytics",
      icon: "analytics",
      permission: useRole([]),
    },
    {
      name: "Our Forms",
      href: "/form",
      icon: "note_add",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.DEPARTMENT_ADMIN,
        ROLE.DEPARTMENT_USER,
      ]),
    },
    {
      name: "All Forms",
      href: "/forms",
      icon: "description",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.COUNCIL,
        ROLE.DEPARTMENT_ADMIN,
        ROLE.DEPARTMENT_USER,
      ]),
    },
    {
      name: "Approve Forms",
      href: "/approve-forms",
      icon: "task",
      permission: useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN]),
    },
    {
      name: "Configs",
      href: "/config",
      icon: "build",
      permission: useRole([ROLE.ADMIN]),
    },
    {
      name: "Verify Family Members",
      href: "/verify-family",
      icon: "task",
      permission: useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER]),
    },
    {
      name: "Settings",
      href: "/settings",
      icon: "settings",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.COUNCIL,
        ROLE.DEPARTMENT_ADMIN,
        ROLE.DEPARTMENT_USER,
        ROLE.USER,
        ROLE.PARK_ADMIN,
        ROLE.PARK_USER,
      ]),
    },
    // {
    //   name: "Help",
    //   href: "/help",
    //   icon: "help",
    //   permission: useRole([
    //     ROLE.ADMIN,
    //     ROLE.COUNCIL,
    //     ROLE.DEPARTMENT_ADMIN,
    //     ROLE.DEPARTMENT_USER,
    //     ROLE.USER,
    //     ROLE.PARK_ADMIN,
    //     ROLE.PARK_USER,
    //   ]),
    // },
  ]);

  return (
    <Fragment>
      <nav className="sticky top-0 z-40 mx-auto flex h-20 max-w-7xl items-center bg-japanese-indigo px-4 sm:px-6 lg:hidden">
        <div className="flex w-full items-center justify-between">
          <Link to="/dashboard">
            <CivixSecondaryIcon />
          </Link>
          <button type="button">
            <HamburgerIcon className="w-6 text-white" />
          </button>
        </div>
      </nav>
      <aside
        className={clsx(
          showSidebar ? "w-60" : "w-20",
          "fixed inset-0 top-20 right-auto hidden h-full overflow-y-auto bg-japanese-indigo transition-all duration-300 lg:block"
        )}
      >
        <div className="relative pt-10 pb-28">
          {showSidebar && (
            <button
              type="button"
              className="absolute right-0 top-0 flex h-28 w-7 items-center justify-center bg-san-juan"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <ChevronLeftIcon className="text-cadet-blue" />
            </button>
          )}
          {!showSidebar && (
            <button
              type="button"
              className="absolute right-0 top-0 flex h-28 w-7 items-center justify-center bg-san-juan"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <ChevronRightIcon className="text-cadet-blue" />
            </button>
          )}
          {/* {showSidebar && (
            <div>
              <img
                className="mx-auto h-12 w-32 object-cover"
                src="/aurora-illinois.png"
                alt=""
              />
            </div>
          )} */}
          <div
            // className={clsx(showSidebar ? "mt-10" : "mt-[88px]", "mb-6")}
            className={clsx(
              showSidebar ? "" : "mt-[88px]",
              "mb-6 transition-all ease-linear"
            )}
          >
            <ul>
              {navigation.map((item, i) => {
                return (
                  item.permission && (
                    <li
                      key={i}
                      className={clsx(
                        showSidebar ? "" : "",
                        "flex select-none items-center"
                      )}
                    >
                      <NavLink
                        to={item.href}
                        title={item.name}
                        className={({ isActive }) =>
                          clsx(
                            isActive
                              ? "border-azure-radiance text-azure-radiance"
                              : "border-transparent text-cadet-blue",
                            "flex h-16 items-center border-l-8 pl-[22px]"
                          )
                        }
                      >
                        <span
                          className={clsx(
                            showSidebar ? "mr-6" : "",
                            "material-icons"
                          )}
                        >
                          {item.icon}
                        </span>
                      </NavLink>
                      <NavLink
                        to={item.href}
                        title={item.name}
                        className={({ isActive }) =>
                          clsx(
                            isActive
                              ? "font-bold tracking-wide text-white"
                              : "text-cadet-blue",
                            "w-32 overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                          )
                        }
                      >
                        {showSidebar && item.name}
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
          {/* <div className="flex justify-center">
            <button
              type="button"
              className={clsx(
                showSidebar ? "lg:w-44" : "w-10",
                "inline-flex h-10 items-center justify-center rounded bg-azure-radiance font-normal tracking-wide text-white"
              )}
            >
              {showSidebar && "New Application"}{" "}
              {!showSidebar && <PlusIcon className="h-6 w-6" />}
            </button>
          </div> */}
        </div>
      </aside>
    </Fragment>
  );
};

export default Sidebar;
