import React from "react";

const TwitterIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V44C0 45.1046 0.89543 46 2 46H44C45.1046 46 46 45.1046 46 44V2C46 0.895431 45.1046 0 44 0H2ZM32.76 15.896C33.816 15.768 34.808 15.48 35.768 15.064C35.096 16.12 34.2 17.048 33.112 17.848C33.144 18.072 33.144 18.296 33.144 18.52C33.144 25.432 27.896 33.432 18.232 33.432C15.256 33.432 12.504 32.568 10.168 31.064C13.528 31.416 16.152 30.296 17.944 28.888C15.672 28.856 13.72 27.32 13.048 25.24C13.752 25.4 14.904 25.304 15.416 25.144C13.048 24.664 11.224 22.52 11.224 19.928C11.928 20.312 12.76 20.568 13.592 20.568C12.216 19.64 10.136 16.888 11.992 13.56C14.584 16.728 18.424 18.84 22.776 19.032C22.68 18.648 22.648 18.264 22.648 17.848C22.648 14.968 24.984 12.6 27.896 12.6C29.4 12.6 30.776 13.24 31.736 14.264C32.92 14.008 34.04 13.592 35.064 12.984C34.68 14.232 33.848 15.256 32.76 15.896Z"
        fill="#DFE3E5"
      />
    </svg>
  );
};

export default TwitterIcon;
