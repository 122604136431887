import React from "react";

const EditPencilIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 19.0001H6.414L15.728 9.68608L14.314 8.27208L5 17.5861V19.0001ZM21 21.0001H3V16.7571L16.435 3.32208C16.6225 3.13461 16.8768 3.0293 17.142 3.0293C17.4072 3.0293 17.6615 3.13461 17.849 3.32208L20.678 6.15108C20.8655 6.33861 20.9708 6.59292 20.9708 6.85808C20.9708 7.12325 20.8655 7.37756 20.678 7.56508L9.243 19.0001H21V21.0001ZM15.728 6.85808L17.142 8.27208L18.556 6.85808L17.142 5.44408L15.728 6.85808Z"
        fill="#4E4B66"
      />
    </svg>
  );
};

export default EditPencilIcon;
