import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBdOsLqUdtEM0RGb1ORbYecC4Hjv2H-Z3c",
  authDomain: "civix-a9ede.firebaseapp.com",
  projectId: "civix-a9ede",
  storageBucket: "civix-a9ede.appspot.com",
  messagingSenderId: "319903393513",
  appId: "1:319903393513:web:72db6ffac988f66baa3278",
  measurementId: "G-2QBHR7WHC7",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setToken) => {
  return getToken(messaging, {
    vapidKey:
      "BBXsOgW_G328dZwUDASsNOh-CdprhtsxTmIE0svPW3DkFKhLBYWXGF6ruA8_gAb2lCGD2IvHOsgkjTfEqFyI99E",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setTokenFound(true);
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
