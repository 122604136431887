import { RETRIEVE_USER_BY_ID } from "actions/types";

const initialState = {};

const userReducer = (user = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_USER_BY_ID:
      return payload;

    default:
      return user;
  }
};

export default userReducer;
