import { GET_ALL_PARK_PROGRAM, GET_ONE_PARK_PROGRAM } from "actions/types";

const initialState = {
  data: [],
};

const parkProgramReducer = (parkPrograms = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PARK_PROGRAM:
      return payload;

    case GET_ONE_PARK_PROGRAM:
      return payload;

    default:
      return parkPrograms;
  }
};

export default parkProgramReducer;
