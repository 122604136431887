import React from "react";

const CivixWhiteIcon = ({ ...props }) => {
  return (
    <svg
      width="94"
      height="32"
      viewBox="0 0 94 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 19.1697C0 11.5456 5.8615 6.33593 13.2347 6.33593C18.983 6.33593 22.1949 9.31338 23.9739 12.3987L19.3601 14.631C18.3009 12.6213 16.0352 11.023 13.2347 11.023C8.8471 11.023 5.52215 14.4826 5.52215 19.1697C5.52215 23.8567 8.8471 27.313 13.2347 27.313C16.0317 27.313 18.3009 25.7517 19.3601 23.705L23.9739 25.9001C22.1572 28.9888 18.983 32 13.2347 32C5.8615 32 0 26.7937 0 19.1697Z"
        fill="white"
      />
      <path
        d="M27.1836 6.74414H32.5549V31.5517H27.1836V6.74414Z"
        fill="white"
      />
      <path
        d="M59.7422 0H66.087L43.0661 31.5549H37.5508L59.7422 0Z"
        fill="white"
      />
      <path d="M66.1531 6.74414V31.5517H60.7852V14.1052" fill="white" />
      <path
        d="M37.5469 6.74414H42.9182V31.5517H37.5469V6.74414Z"
        fill="white"
      />
      <path
        d="M81.2567 22.4978L74.905 31.5717H68.5156L77.6644 18.8527L69.0812 6.76416H75.4706L81.2533 15.2447L86.9639 6.76416H93.4287L84.849 18.8156L93.9977 31.5717H87.5707L81.2567 22.4978Z"
        fill="white"
      />
    </svg>
  );
};

export default CivixWhiteIcon;
