import React from "react";

const CaretRightIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.611877 0.404638C0.473083 0.463232 0.382812 0.599096 0.382812 0.749975L0.382996 17.25C0.382996 17.4007 0.473267 17.5367 0.612061 17.5955C0.750854 17.6541 0.911438 17.6239 1.01947 17.5188L9.50476 9.26877C9.57727 9.19827 9.61829 9.10123 9.61829 8.99997C9.61829 8.89871 9.57727 8.80167 9.50476 8.73117L1.01929 0.481176C0.911071 0.376074 0.750671 0.346045 0.611877 0.404638Z"
        fill="white"
      />
    </svg>
  );
};

export default CaretRightIcon;
