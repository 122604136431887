import React, { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { XIcon } from "components/icons";

import Button from "../Button";

const LogoutModal = ({ isLogoutModal, setIsLogoutModal }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onLogout = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      localStorage.removeItem("cvxu");
      localStorage.removeItem("cvx-u");

      navigate("/signin");
      toast.success("Logged out successfully.");
      setIsLogoutModal(false);
    }, 2000);
  };

  return (
    <Transition appear show={isLogoutModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => setIsLogoutModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-6 flex items-center justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-normal text-independence"
                  >
                    Logout
                  </Dialog.Title>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center outline-none"
                    onClick={() => setIsLogoutModal(false)}
                  >
                    <XIcon className="h-3 w-3 text-storm-gray" />
                  </button>
                </div>
                <div className="mt-2 mb-6 text-center text-independence">
                  Are you sure you want to logout?
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <Button
                    type="button"
                    outlined
                    onClick={() => setIsLogoutModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    width="32"
                    loading={loading}
                    onClick={() => onLogout()}
                  >
                    Confirm
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LogoutModal;
