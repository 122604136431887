import authHeader from "auth-header";
import http from "http-common";

const sendMail = (data) => {
  return http.post("/contact-us", data, { headers: authHeader() });
};

const ContactUsService = {
  sendMail,
};

export default ContactUsService;
