import React from "react";

const MagnifyingGlassIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62498 8.49998H9.03248L8.82248 8.29748C9.72248 7.24748 10.1875 5.81498 9.93248 4.29248C9.57998 2.20748 7.83998 0.542481 5.73998 0.287481C2.56748 -0.102519 -0.102519 2.56748 0.287481 5.73998C0.542481 7.83998 2.20748 9.57998 4.29248 9.93248C5.81498 10.1875 7.24748 9.72248 8.29748 8.82248L8.49998 9.03248V9.62498L11.6875 12.8125C11.995 13.12 12.4975 13.12 12.805 12.8125C13.1125 12.505 13.1125 12.0025 12.805 11.695L9.62498 8.49998ZM5.12498 8.49998C3.25748 8.49998 1.74998 6.99248 1.74998 5.12498C1.74998 3.25748 3.25748 1.74998 5.12498 1.74998C6.99248 1.74998 8.49998 3.25748 8.49998 5.12498C8.49998 6.99248 6.99248 8.49998 5.12498 8.49998Z"
        fill="#6E7191"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
