import React from "react";

const ApplicationsIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.2353 2.34636H10.1137L8.49608 0.422827C8.4478 0.364788 8.37545 0.332239 8.3 0.334592H1.76471C0.786472 0.344298 -0.00112624 1.14062 1.20895e-06 2.11891V13.8836C-4.78107e-05 14.8611 0.787256 15.6563 1.76471 15.666H18.2353C19.2128 15.6563 20.0001 14.8611 20 13.8836V4.12871C20.0001 3.15121 19.2128 2.35606 18.2353 2.34636Z" />
    </svg>
  );
};

export default ApplicationsIcon;
