import { RETRIEVE_APPLICATION, RETRIEVE_APPLICATIONS } from "actions/types";

const initialState = {
  data: [],
};

const applicationReducer = (applications = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_APPLICATION:
      return payload;

    case RETRIEVE_APPLICATIONS:
      return payload;

    default:
      return applications;
  }
};

export default applicationReducer;
