import React, { createContext, useState } from "react";

export const AppContext = createContext();

export function ContextProvider({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const [role, setRole] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [formGroupItems, setFormGroupItems] = useState([]);
  const [applicationQuery, setApplicationQuery] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  return (
    <AppContext.Provider
      value={{
        isAuth,
        setIsAuth,
        role,
        setRole,
        showSidebar,
        setShowSidebar,
        isOpen,
        setIsOpen,
        formGroupItems,
        setFormGroupItems,
        applicationQuery,
        setApplicationQuery,
        selectedCity,
        setSelectedCity,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
