import CityService from "services/CityService";

import {
  CREATE_CITY,
  DELETE_CITY,
  RETRIEVE_CITIES,
  RETRIEVE_CITY,
  UPDATE_CITY,
} from "./types";

export const createCity = (data) => async (dispatch) => {
  try {
    const res = await CityService.create(data);

    dispatch({
      type: CREATE_CITY,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveCity = (stateId) => async (dispatch) => {
  try {
    const res = await CityService.getAll(stateId);

    dispatch({
      type: RETRIEVE_CITY,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveCities = (params) => async (dispatch) => {
  try {
    const res = await CityService.getCities(params);

    dispatch({
      type: RETRIEVE_CITIES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCity = (id, data) => async (dispatch) => {
  try {
    const res = await CityService.update(id, data);

    dispatch({
      type: UPDATE_CITY,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteCity = (id) => async (dispatch) => {
  try {
    const res = await CityService.remove(id);

    dispatch({
      type: DELETE_CITY,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
