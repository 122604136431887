import { RETRIEVE_SUBCATEGORIES } from "actions/types";

const initialState = {
  data: [],
};

const parkSubcategoryReducer = (parkSubcategories = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_SUBCATEGORIES:
      return payload;

    default:
      return parkSubcategories;
  }
};

export default parkSubcategoryReducer;
