import React from "react"

const CircleCrossIcon = ({ ...props }) => {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <circle cx="24.5" cy="24.5" r="24.5" fill="#ED6A61" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.3007 18.7778C30.9107 18.3831 30.2807 18.3831 29.8907 18.7778L25.0007 23.7157L20.1107 18.7677C19.7207 18.373 19.0907 18.373 18.7007 18.7677C18.3107 19.1623 18.3107 19.7998 18.7007 20.1944L23.5907 25.1425L18.7007 30.0905C18.3107 30.4852 18.3107 31.1227 18.7007 31.5173C19.0907 31.9119 19.7207 31.9119 20.1107 31.5173L25.0007 26.5692L29.8907 31.5173C30.2807 31.9119 30.9107 31.9119 31.3007 31.5173C31.6907 31.1227 31.6907 30.4852 31.3007 30.0905L26.4107 25.1425L31.3007 20.1944C31.6807 19.8099 31.6807 19.1623 31.3007 18.7778Z" fill="white" />
    </svg>
  )
}

export default CircleCrossIcon