import { GET_ALL_PARK_CATEGORY } from "actions/types";

const initialState = {
  data: [],
};

const parkCategoryReducer = (parkCategories = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PARK_CATEGORY:
      return payload;

    default:
      return parkCategories;
  }
};

export default parkCategoryReducer;
