import { GET_ALL_PROGRAM_APPROVAL } from "actions/types";

const initialState = {
  data: [],
};

const parkProgramApprovalReducer = (
  programApprovals = initialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PROGRAM_APPROVAL:
      return payload;

    default:
      return programApprovals;
  }
};

export default parkProgramApprovalReducer;
