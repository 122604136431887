import React from "react";

const TrashIcon = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.33268 4.83341V14.8334C3.33268 15.2937 3.70578 15.6667 4.16602 15.6667H10.8327C11.2929 15.6667 11.666 15.2937 11.666 14.8334V4.83341H3.33268ZM2.49935 3.16675H12.4994C12.9596 3.16675 13.3327 3.53984 13.3327 4.00008V14.8334C13.3327 16.2141 12.2134 17.3334 10.8327 17.3334H4.16602C2.7853 17.3334 1.66602 16.2141 1.66602 14.8334V4.00008C1.66602 3.53984 2.03911 3.16675 2.49935 3.16675Z" />
      <path d="M0.833333 4.83341C0.373096 4.83341 0 4.46032 0 4.00008C0 3.53984 0.373096 3.16675 0.833333 3.16675H14.1667C14.6269 3.16675 15 3.53984 15 4.00008C15 4.46032 14.6269 4.83341 14.1667 4.83341H0.833333Z" />
      <path d="M5.83333 2.33341C5.3731 2.33341 5 1.96032 5 1.50008C5 1.03984 5.3731 0.666748 5.83333 0.666748H9.16667C9.6269 0.666748 10 1.03984 10 1.50008C10 1.96032 9.6269 2.33341 9.16667 2.33341H5.83333Z" />
      <path d="M5 7.33333C5 6.8731 5.3731 6.5 5.83334 6.5C6.29358 6.5 6.66668 6.8731 6.66668 7.33333V12.3333C6.66668 12.7936 6.29358 13.1667 5.83334 13.1667C5.3731 13.1667 5 12.7936 5 12.3333V7.33333Z" />
      <path d="M8.33398 7.33333C8.33398 6.8731 8.70708 6.5 9.16732 6.5C9.62756 6.5 10.0007 6.8731 10.0007 7.33333V12.3333C10.0007 12.7936 9.62756 13.1667 9.16732 13.1667C8.70708 13.1667 8.33398 12.7936 8.33398 12.3333V7.33333Z" />
    </svg>
  );
};

export default TrashIcon;
