import authHeader from "auth-header";
import http from "http-common";

const signIn = async (data) => {
  return http.post("/auth/login", data);
};

const signUp = (data) => {
  return http.post("/sign-up", data);
};

const forgotPassword = (data) => {
  return http.post("/auth/forgetPassword", data);
};

const resetPassword = (data) => {
  return http.post("/auth/resetPassword", data);
};

const verifyUser = (id) => {
  return http.get(`/verify/${id}`);
};

const getAll = (params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };

  return http.get("/users", config);
};

const get = async (userId, params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };

  const response = await http.get(`/users/${userId}`, config);
  return response;
};

const update = (id, data) => {
  return http.patch(`/users/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/user/${id}`, { headers: authHeader() });
};

const userSubscriptionCheck = () => {
  return http.get("/user/flag/application", { headers: authHeader() });
};

const changePassword = (data) => {
  return http.post("/users/changePassword", data, { headers: authHeader() });
};

const createToken = (data) => {
  return http.post("/user/token", data);
};

const renewTokens = (data) => {
  return http.post("/auth/refreshToken", data);
};

const UserService = {
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  verifyUser,
  getAll,
  get,
  update,
  remove,
  createToken,
  renewTokens,
  changePassword,
  userSubscriptionCheck,
};

export default UserService;
