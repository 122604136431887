import { RETRIEVE_FAMILY_MEMBER } from "actions/types";

const initialState = {};

const familyMemberReducer = (member = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_FAMILY_MEMBER:
      return payload;

    default:
      return member;
  }
};

export default familyMemberReducer;
