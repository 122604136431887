import React from "react";

const ApplicationIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.8 1.6V7.2C8.8 7.62435 8.63143 8.03131 8.33137 8.33137C8.03131 8.63143 7.62435 8.8 7.2 8.8H1.6C1.17565 8.8 0.768687 8.63143 0.468629 8.33137C0.168571 8.03131 0 7.62435 0 7.2V1.6C0 1.17565 0.168571 0.768687 0.468629 0.468629C0.768687 0.168571 1.17565 0 1.6 0H7.2C7.62435 0 8.03131 0.168571 8.33137 0.468629C8.63143 0.768687 8.8 1.17565 8.8 1.6ZM18.4 0H12.8C12.3757 0 11.9687 0.168571 11.6686 0.468629C11.3686 0.768687 11.2 1.17565 11.2 1.6V7.2C11.2 7.62435 11.3686 8.03131 11.6686 8.33137C11.9687 8.63143 12.3757 8.8 12.8 8.8H18.4C18.8243 8.8 19.2313 8.63143 19.5314 8.33137C19.8314 8.03131 20 7.62435 20 7.2V1.6C20 1.17565 19.8314 0.768687 19.5314 0.468629C19.2313 0.168571 18.8243 0 18.4 0ZM7.2 11.2H1.6C1.17565 11.2 0.768687 11.3686 0.468629 11.6686C0.168571 11.9687 0 12.3757 0 12.8V18.4C0 18.8243 0.168571 19.2313 0.468629 19.5314C0.768687 19.8314 1.17565 20 1.6 20H7.2C7.62435 20 8.03131 19.8314 8.33137 19.5314C8.63143 19.2313 8.8 18.8243 8.8 18.4V12.8C8.8 12.3757 8.63143 11.9687 8.33137 11.6686C8.03131 11.3686 7.62435 11.2 7.2 11.2ZM15.6 11.2C14.7298 11.2 13.8791 11.4581 13.1555 11.9415C12.4319 12.425 11.868 13.1122 11.5349 13.9162C11.2019 14.7202 11.1148 15.6049 11.2845 16.4584C11.4543 17.3119 11.8734 18.0959 12.4887 18.7113C13.1041 19.3266 13.8881 19.7457 14.7416 19.9155C15.5951 20.0852 16.4798 19.9981 17.2838 19.6651C18.0878 19.332 18.775 18.7681 19.2585 18.0445C19.7419 17.3209 20 16.4702 20 15.6C20 14.433 19.5364 13.3139 18.7113 12.4887C17.8861 11.6636 16.767 11.2 15.6 11.2Z" />
    </svg>
  );
};

export default ApplicationIcon;
