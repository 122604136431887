import React from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { CivixWhiteIcon } from "components/icons";
import { Button } from "components/ui";

import { sendMail } from "actions/contactUs";

const footer = {
  brand: CivixWhiteIcon,
  copyright: "2023 NewLogix, Inc. All Rights Reserved.",
  items: [
    // { name: "About Us", href: "/about" },
    // { name: "FAQ", href: "/faq" },
    { name: "Terms of Service", href: "/terms" },
    { name: "Privacy Policy", href: "/privacy-policy" },
    { name: "Contact Us", href: "/contact" },
    // { name: "Subscription", href: "/subscription" },
  ],
};

const Footer = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const body = data;

    dispatch(sendMail(body))
      .then((res) => {
        console.log(res, "res");
        setValue("name", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("subject", "");
        setValue("message", "");

        toast.success(
          "Thanks for your message! A member of our team will be contacting you within 24-48 hours."
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <footer>
      <div>
        <div className="bg-white py-20">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mb-10 max-w-2xl">
              <h3 className="mb-6 text-3xl font-semibold">
                Have Questions? Let’s Talk
              </h3>
              <p className="text-sm font-light leading-6 tracking-wide">
                Our Client Support Specialists are here to help! Fill up our
                contact form and we will contact you within 24-48 hours.
              </p>
            </div>
            <form
              className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-10 xl:gap-16"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="space-y-6">
                <div>
                  <input
                    type="text"
                    className={clsx(
                      errors?.name?.type === "required"
                        ? "border-[#ff0000] placeholder:text-[#ff0000] focus:border-[#ff0000]"
                        : "border-black/40 focus:border-black/40",
                      "mt-0 block w-full border-0 border-b bg-transparent px-0.5 font-light leading-8 tracking-wide focus:ring-0"
                    )}
                    placeholder="Name*"
                    {...register("name", { required: true })}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className={clsx(
                      errors?.email?.type === "required"
                        ? "border-[#ff0000] placeholder:text-[#ff0000] focus:border-[#ff0000]"
                        : "border-black/40 focus:border-black/40",
                      "mt-0 block w-full border-0 border-b bg-transparent px-0.5 font-light leading-8 tracking-wide focus:ring-0"
                    )}
                    placeholder="Email*"
                    {...register("email", { required: true })}
                  />
                </div>
              </div>
              <div className="space-y-6">
                <div>
                  <input
                    type="tel"
                    className={clsx(
                      errors?.phone?.type === "required"
                        ? "border-[#ff0000] placeholder:text-[#ff0000] focus:border-[#ff0000]"
                        : "border-black/40 focus:border-black/40",
                      "mt-0 block w-full border-0 border-b bg-transparent px-0.5 font-light leading-8 tracking-wide focus:ring-0"
                    )}
                    placeholder="Phone"
                    {...register("phone", { required: false })}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className={clsx(
                      errors?.subject?.type === "required"
                        ? "border-[#ff0000] placeholder:text-[#ff0000] focus:border-[#ff0000]"
                        : "border-black/40 focus:border-black/40",
                      "mt-0 block w-full border-0 border-b bg-transparent px-0.5 font-light leading-8 tracking-wide focus:ring-0"
                    )}
                    placeholder="Subject*"
                    {...register("subject", { required: true })}
                  />
                </div>
              </div>
              <div>
                <textarea
                  rows={3}
                  maxLength={255}
                  className={clsx(
                    errors?.message?.type === "required" ||
                      errors?.message?.type === "maxLength"
                      ? "border-[#ff0000] placeholder:text-[#ff0000] focus:border-[#ff0000]"
                      : "border-black/40 focus:border-black/40",
                    "mt-0 block w-full resize-none border-0 border-b bg-transparent px-0.5 font-light leading-8 tracking-wide focus:ring-0"
                  )}
                  placeholder="Message*"
                  {...register("message", {
                    required: true,
                    maxLength: 255,
                  })}
                />
              </div>
              <div className="flex justify-center lg:col-span-3">
                <Button type="submit" width="40" outlined>
                  Send Message
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="bg-yankees-blue py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mb-6 flex flex-col items-center justify-between lg:flex-row">
              <div className="mb-8 lg:mb-0">
                <div className="mb-8 flex w-full justify-center lg:justify-start">
                  <Link to="/" title="Civix">
                    <footer.brand className="w-24" />
                  </Link>
                </div>
                <ul className="flex flex-col items-center space-y-4 text-sm font-light leading-6 text-white lg:flex-row lg:space-x-12 lg:space-y-0">
                  {footer.items.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link to={item.href} title={item.name}>
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="text-center text-white lg:text-right">
                <div className="mb-4">
                  444 N. Michigan Ave., <br /> Suite 1200 <br /> Chicago, IL
                  60611
                </div>
                <div className="font-light">(888) 540-3775</div>
              </div>
            </div>
            <div className="text-center text-sm font-light leading-6 text-white/30">
              {footer.copyright}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
