import React from "react";

const DepartmentsIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.104812 19.7482C0.214108 19.9055 0.393756 20 0.586022 20H19.414C19.6062 20 19.7859 19.9055 19.8952 19.7482C20.005 19.5903 20.0302 19.3889 19.9627 19.2086C19.7344 18.5981 19.3076 18.0625 19.1342 17.8611C19.0231 17.7312 18.8606 17.6562 18.6895 17.6562H1.31043C1.13934 17.6562 0.976841 17.7312 0.865826 17.8611C0.692467 18.0625 0.265593 18.5981 0.0372727 19.2086C-0.0302272 19.3889 -0.005071 19.5903 0.104812 19.7482Z" />
      <path d="M2.34375 9.41406H5.89843V16.4844H2.34375V9.41406Z" />
      <path d="M8.24219 9.41406H11.7578V16.4844H8.24219V9.41406Z" />
      <path d="M14.1016 9.41406H17.6563V16.4844H14.1016V9.41406Z" />
      <path d="M1.26824 8.06964C1.37824 8.18007 1.53133 8.24218 1.68273 8.24218C1.68281 8.24218 1.68293 8.2421 1.683 8.2421C1.68308 8.2421 1.6832 8.24218 1.68328 8.24179H18.3167C18.3168 8.24218 18.3169 8.2421 18.317 8.2421C18.317 8.2421 18.3172 8.24218 18.3172 8.24218C18.4686 8.24218 18.6217 8.18011 18.7317 8.06964C18.8509 7.95042 19.742 7.0225 19.9999 5.89844H0C0.258007 7.0225 1.14898 7.95042 1.26824 8.06964Z" />
      <path d="M19.6272 4.72655L10.2622 0.0617871C10.0974 -0.0205957 9.90285 -0.0205957 9.73805 0.0617871L0.373047 4.72655H19.6272Z" />
    </svg>
  );
};

export default DepartmentsIcon;
