import React, { Fragment, useContext, useEffect } from "react";

import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { renewTokens } from "actions/user";

import { AppContext } from "context/AppContext";

import AppRoutes from "routes/AppRoutes";

import ScrollToTop from "utils/ScrollToTop";

import { onMessageListener } from "./firebase";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function App() {
  const navigate = useNavigate();
  const { setIsAuth } = useContext(AppContext);
  const dispatch = useDispatch();

  onMessageListener()
    .then((payload) => {
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    const localData = localStorage.getItem("cvxu");
    if (localData !== null) {
      setIsAuth(true);
      const decode = parseJwt(localData);
      if (decode.exp * 1000 < Date.now()) {
        //token expire call refresh token
        let userObj = JSON.parse(localData);
        const body = {
          refreshToken: userObj?.refreshToken,
        };
        dispatch(renewTokens(body))
          .then((response) => {
            //update localstorage with newToken
            localStorage.removeItem("cvxu");
            localStorage.setItem("cvxu", JSON.stringify(response));
          })
          .catch((err) => {
            //refreshToken is expired user should logout
            localStorage.removeItem("cvxu");
            localStorage.removeItem("cvx-u");
            navigate("/");
            setIsAuth(false);
          });
      }
    }
  }, [setIsAuth, navigate, dispatch]);

  return (
    <Fragment>
      <ScrollToTop />
      <AppRoutes />
      <Toaster position="top-center" reverseOrder={false} />
    </Fragment>
  );
}

export default App;
