import { RETRIEVE_DEPARTMENT } from "actions/types";

const initialState = {
  data: [],
};

const departmentReducer = (departments = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_DEPARTMENT:
      return payload;

    default:
      return departments;
  }
};

export default departmentReducer;
