import React from "react";

import { useNavigate } from "react-router-dom";

import Button from "../Button";

const NoDataFound = ({ content = "No Data Available" }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="mb-8">
        <img
          src="/img/no_data.svg"
          alt="No Data"
          className="mx-auto h-[280px] w-[280px]"
        />
      </div>
      <div className="mb-4 text-center text-xl font-semibold text-japanese-indigo">
        {content}
      </div>
      <div className="flex justify-center">
        <Button type="button" width="32" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NoDataFound;
