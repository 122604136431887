import React, { lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import { Layout, PrimaryLayout, PrimaryNavbar } from "components/common";
import { Loading } from "components/ui";

import useRole from "hooks/useRole";

import { ROLE } from "utils/roles";

import ProtectedRoute from "./ProtectedRoute";

const CityMarketingList = lazy(() => import("pages/CityMarketingList"));
const ParkCategories = lazy(() => import("pages/ParkCategories"));
const ParkSubCategory = lazy(() => import("pages/ParkSubCategory"));
const SignIn = lazy(() => import("pages/auth/signin"));
const VerifyFamily = lazy(() => import("pages/VerifyFamily"));
const Config = lazy(() => import("pages/Config"));
const SignUp = lazy(() => import("pages/auth/signup"));
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));
const VerifyToken = lazy(() => import("pages/verify/verifytoken"));
const Home = lazy(() => import("pages"));
const Clients = lazy(() => import("pages/clients"));
const City = lazy(() => import("pages/City"));
const Event = lazy(() => import("pages/Event"));
const Events = lazy(() => import("pages/Events"));
const Help = lazy(() => import("pages/help"));
const About = lazy(() => import("pages/about"));
const Faq = lazy(() => import("pages/faq"));
const Contact = lazy(() => import("pages/contact"));
const Terms = lazy(() => import("pages/terms"));
const PrivacyPolicy = lazy(() => import("pages/privacypolicy"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Users = lazy(() => import("pages/Users"));
const User = lazy(() => import("pages/users/User"));
const Applications = lazy(() => import("pages/applications"));
const Cities = lazy(() => import("pages/Cities"));
const Subscription = lazy(() => import("pages/Subscription"));
const States = lazy(() => import("pages/States"));
const Countries = lazy(() => import("pages/Countries"));
const ZonalDepartmentsByCity = lazy(() =>
  import("pages/cities/ZonalDepartments")
);
const ZonalDepartmentDetails = lazy(() =>
  import("pages/cities/ZonalDepartmentDetails")
);
const Analytics = lazy(() => import("pages/analytics"));
const Form = lazy(() => import("pages/form"));
const EditForm = lazy(() => import("pages/EditForm"));
const Forms = lazy(() => import("pages/forms/Forms"));
const ApplicationView = lazy(() => import("pages/forms/ApplicationView"));
const ApplicationDetailsView = lazy(() =>
  import("pages/ApplicationDetailsView")
);
const ApproveForms = lazy(() => import("pages/forms/ApproveForms"));
const Calendar = lazy(() => import("pages/calendar"));
const ZonalDepartment = lazy(() => import("pages/ZonalDepartment"));
const Council = lazy(() => import("pages/council"));
const Department = lazy(() => import("pages/department"));
const DepartmentUser = lazy(() => import("pages/DepartmentUser"));
const Map = lazy(() => import("pages/map"));
const Programs = lazy(() => import("pages/Programs"));
const Program = lazy(() => import("pages/programs/Program"));
const Payment = lazy(() => import("pages/Payment"));
const Settings = lazy(() => import("pages/settings"));
const FormBuilder = lazy(() => import("pages/FormBuilder"));
const MyApplication = lazy(() => import("pages/MyApplication"));
const DraftAppliComplete = lazy(() => import("pages/DraftAppliComplete"));
const ApplicationDetails = lazy(() => import("pages/ApplicationDetails"));
const TransferApplication = lazy(() => import("pages/TransferApplication"));
const ViewApplication = lazy(() => import("pages/ViewApplication"));
const Location = lazy(() => import("pages/location"));
const AddEditProgramEvent = lazy(() =>
  import("pages/parkprograms/AddEditProgramEvent")
);
const ProgramReport = lazy(() => import("pages/parkprograms/ProgramReport"));
const ProgramApprovalReport = lazy(() =>
  import("pages/parkprograms/ProgramApprovalReport")
);
const ProgramPreview = lazy(() => import("pages/parkprograms/ProgramPreview"));
const EmailTemplate = lazy(() => import("pages/EmailTemplate"));
const PaymentStatus = lazy(() => import("pages/payment/PaymentStatus"));

const AppRoutes = () => {
  return (
    <Routes>
      {/* Unprotected Routes */}
      <Route element={<Layout />}>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          path="signin"
          element={
            <Suspense fallback={<Loading />}>
              <SignIn />
            </Suspense>
          }
        />
        <Route
          path="signup"
          element={
            <Suspense fallback={<Loading />}>
              <SignUp />
            </Suspense>
          }
        />
        <Route
          path="forgot-password"
          element={
            <Suspense fallback={<Loading />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="auth/reset/:token"
          element={
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="verify/:token"
          element={
            <Suspense fallback={<Loading />}>
              <VerifyToken />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/clients"
          element={
            <Suspense fallback={<Loading />}>
              <Clients />
            </Suspense>
          }
        />
        <Route
          path="/city"
          element={
            <Suspense fallback={<Loading />}>
              <City />
            </Suspense>
          }
        />
        <Route
          path={"payment/:status/:sessionId"}
          element={
            <Suspense fallback={<Loading />}>
              <PaymentStatus />
            </Suspense>
          }
        />
        <Route
          path={"payment/:status"}
          element={
            <Suspense fallback={<Loading />}>
              <PaymentStatus />
            </Suspense>
          }
        />
        <Route
          path="/subscription"
          element={
            <Suspense fallback={<Loading />}>
              <Subscription />
            </Suspense>
          }
        />
        <Route
          path="/events/:categoryId"
          element={
            <Suspense fallback={<Loading />}>
              <Events />
            </Suspense>
          }
        />
        <Route
          path="/event/:eventId"
          element={
            <Suspense fallback={<Loading />}>
              <Event />
            </Suspense>
          }
        />
        <Route
          path="help"
          element={
            <Suspense fallback={<Loading />}>
              <Help />
            </Suspense>
          }
        />
        <Route
          path="about"
          element={
            <Suspense fallback={<Loading />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="faq"
          element={
            <Suspense fallback={<Loading />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path="terms"
          element={
            <Suspense fallback={<Loading />}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Suspense fallback={<Loading />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="contact"
          element={
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          }
        />
      </Route>

      {/* Protected Routes */}
      <Route element={<PrimaryNavbar />}>
        <Route
          path="/my-application/:applicationId"
          element={<ProtectedRoute permission={useRole([ROLE.USER])} />}
        >
          <Route
            path="/my-application/:applicationId"
            element={
              <Suspense fallback={<Loading />}>
                <MyApplication />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/draft-application/:id"
          element={<ProtectedRoute permission={useRole([ROLE.USER])} />}
        >
          <Route
            path="/draft-application/:id"
            element={
              <Suspense fallback={<Loading />}>
                <DraftAppliComplete />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/my-application/:applicationId/view"
          element={<ProtectedRoute permission={useRole([ROLE.USER])} />}
        >
          <Route
            path="/my-application/:applicationId/view"
            element={
              <Suspense fallback={<Loading />}>
                <ApplicationDetailsView />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/payment"
          element={
            <Suspense fallback={<Loading />}>
              <Payment />
            </Suspense>
          }
        />
        <Route
          path="/forms/view/:formId"
          element={
            <Suspense fallback={<Loading />}>
              <ApplicationView />
            </Suspense>
          }
        />
        <Route
          path="/applications/:id/view"
          element={<ProtectedRoute permission={useRole([ROLE.USER])} />}
        >
          <Route
            path="/applications/:id/view"
            element={
              <Suspense fallback={<Loading />}>
                <ApplicationDetails />
              </Suspense>
            }
          />
        </Route>
        <Route element={<PrimaryLayout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.COUNCIL,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                  ROLE.USER,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<Loading />}>
                  <Dashboard />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/users"
            element={<ProtectedRoute permission={useRole([ROLE.ADMIN])} />}
          >
            <Route
              path="/users"
              element={
                <Suspense fallback={<Loading />}>
                  <Users />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/users/:id"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/users/:id"
              element={
                <Suspense fallback={<Loading />}>
                  <User />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/config"
            element={<ProtectedRoute permission={useRole([ROLE.ADMIN])} />}
          >
            <Route
              path="/config"
              element={
                <Suspense fallback={<Loading />}>
                  <Config />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="/city-list"
            element={<ProtectedRoute permission={useRole([ROLE.ADMIN])} />}
          >
            <Route
              path="/city-list"
              element={
                <Suspense fallback={<Loading />}>
                  <CityMarketingList />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/department-users"
            element={
              <ProtectedRoute permission={useRole([ROLE.DEPARTMENT_ADMIN])} />
            }
          >
            <Route
              path="/department-users"
              element={
                <Suspense fallback={<Loading />}>
                  <DepartmentUser />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/applications"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                  ROLE.USER,
                ])}
              />
            }
          >
            <Route
              path="/applications"
              element={
                <Suspense fallback={<Loading />}>
                  <Applications />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/programs"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER])}
              />
            }
          >
            <Route
              path="/programs"
              element={
                <Suspense fallback={<Loading />}>
                  <Programs />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/park-category"
            element={<ProtectedRoute permission={useRole([ROLE.ADMIN])} />}
          >
            <Route
              path="/park-category"
              element={
                <Suspense fallback={<Loading />}>
                  <ParkCategories />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/park-sub-category"
            element={<ProtectedRoute permission={useRole([ROLE.ADMIN])} />}
          >
            <Route
              path="/park-sub-category"
              element={
                <Suspense fallback={<Loading />}>
                  <ParkSubCategory />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/transfer-application"
            element={
              <ProtectedRoute permission={useRole([ROLE.DEPARTMENT_ADMIN])} />
            }
          >
            <Route
              path="/transfer-application"
              element={
                <Suspense fallback={<Loading />}>
                  <TransferApplication />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/applications/:id"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                ])}
              />
            }
          >
            <Route
              path="/applications/:id"
              element={
                <Suspense fallback={<Loading />}>
                  <ViewApplication />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/cities"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/cities"
              element={
                <Suspense fallback={<Loading />}>
                  <Cities />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/states"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/states"
              element={
                <Suspense fallback={<Loading />}>
                  <States />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/countries"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/countries"
              element={
                <Suspense fallback={<Loading />}>
                  <Countries />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/cities/:cityId"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/cities/:cityId"
              element={
                <Suspense fallback={<Loading />}>
                  <ZonalDepartmentsByCity />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/zonal-departments/:departmentId"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.COUNCIL,
                  ROLE.DEPARTMENT_ADMIN,
                ])}
              />
            }
          >
            <Route
              path="/zonal-departments/:departmentId"
              element={
                <Suspense fallback={<Loading />}>
                  <ZonalDepartmentDetails />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/analytics"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/analytics"
              element={
                <Suspense fallback={<Loading />}>
                  <Analytics />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/forms"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.COUNCIL,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                ])}
              />
            }
          >
            <Route
              path="/forms"
              element={
                <Suspense fallback={<Loading />}>
                  <Forms />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/approve-forms"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/approve-forms"
              element={
                <Suspense fallback={<Loading />}>
                  <ApproveForms />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/form"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                ])}
              />
            }
          >
            <Route
              path="/form"
              element={
                <Suspense fallback={<Loading />}>
                  <Form />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/forms/:id/edit"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                ])}
              />
            }
          >
            <Route
              path="/forms/:id/edit"
              element={
                <Suspense fallback={<Loading />}>
                  <EditForm />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/calendar"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/calendar"
              element={
                <Suspense fallback={<Loading />}>
                  <Calendar />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/zonal-departments"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.COUNCIL,
                  ROLE.DEPARTMENT_ADMIN,
                ])}
              />
            }
          >
            <Route
              path="/zonal-departments"
              element={
                <Suspense fallback={<Loading />}>
                  <ZonalDepartment />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/council"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/council"
              element={
                <Suspense fallback={<Loading />}>
                  <Council />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/departments"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/departments"
              element={
                <Suspense fallback={<Loading />}>
                  <Department />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/map"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/map"
              element={
                <Suspense fallback={<Loading />}>
                  <Map />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/settings"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.COUNCIL,
                  ROLE.DEPARTMENT_ADMIN,
                  ROLE.DEPARTMENT_USER,
                  ROLE.USER,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/settings"
              element={
                <Suspense fallback={<Loading />}>
                  <Settings />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/form-builder"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.ADMIN, ROLE.DEPARTMENT_ADMIN])}
              />
            }
          >
            <Route
              path="/form-builder"
              element={
                <Suspense fallback={<Loading />}>
                  <FormBuilder />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/programs/:id"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.ADMIN,
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                ])}
              />
            }
          >
            <Route
              path="/programs/:id"
              element={
                <Suspense fallback={<Loading />}>
                  <Program />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/program-view"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER])}
              />
            }
          >
            <Route
              path="/program-view"
              element={
                <Suspense fallback={<Loading />}>
                  <AddEditProgramEvent />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/verify-family"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER])}
              />
            }
          >
            <Route
              path="/verify-family"
              element={
                <Suspense fallback={<Loading />}>
                  <VerifyFamily />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/location"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER])}
              />
            }
          >
            <Route
              path="/location"
              element={
                <Suspense fallback={<Loading />}>
                  <Location />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/program-forms"
            element={
              <ProtectedRoute
                permission={useRole([ROLE.PARK_ADMIN, ROLE.PARK_USER])}
              />
            }
          >
            <Route
              path="/program-forms"
              element={
                <Suspense fallback={<Loading />}>
                  <ProgramReport />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/approved-program"
            element={<ProtectedRoute permission={useRole([ROLE.PARK_ADMIN])} />}
          >
            <Route
              path="/approved-program"
              element={
                <Suspense fallback={<Loading />}>
                  <ProgramApprovalReport />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/programs/view/:programId"
            element={
              <ProtectedRoute
                permission={useRole([
                  ROLE.PARK_ADMIN,
                  ROLE.PARK_USER,
                  ROLE.USER,
                ])}
              />
            }
          >
            <Route
              path="/programs/view/:programId"
              element={
                <Suspense fallback={<Loading />}>
                  <ProgramPreview />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>

      <Route
        path="/email-template"
        element={<ProtectedRoute permission={useRole([])} />}
      >
        <Route
          path="/email-template"
          element={
            <Suspense fallback={<Loading />}>
              <EmailTemplate />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
