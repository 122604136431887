import { combineReducers } from "redux";

import applicationApproval from "./applicationApproval";
import applications from "./applications";
import auth from "./auth";
import cities from "./cities";
import cityMarketing from "./cityMarketing";
import config from "./config";
import councils from "./councils";
import countries from "./countries";
import dashboard from "./dashboard";
import departments from "./departments";
import departmentUsers from "./departmentUsers";
import familyMembers from "./familyMembers";
import formElements from "./formElements";
import formGroups from "./formGroups";
import parkCategories from "./parkCategory";
import parkLocations from "./parkLocations";
import parkPrograms from "./parkProgram";
import parkProgramApprovals from "./parkProgramApproval";
import parkSeasons from "./parkSeason";
import parkSubcategory from "./parkSubcategory";
import states from "./states";
import subscription from "./subscription";
import user from "./user";
import users from "./users";
import zonalDepartments from "./zonalDepartments";

export default combineReducers({
  applicationApproval,
  applications,
  auth,
  cities,
  config,
  councils,
  countries,
  dashboard,
  departments,
  departmentUsers,
  familyMembers,
  formElements,
  formGroups,
  parkCategories,
  parkLocations,
  parkPrograms,
  parkProgramApprovals,
  parkSeasons,
  parkSubcategory,
  states,
  user,
  users,
  zonalDepartments,
  subscription,
  cityMarketing,
});
