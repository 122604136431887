import { RETRIEVE_FORM_ELEMENT, RETRIEVE_FORM_ELEMENTS } from "actions/types";

const initialState = {
  data: [],
};

const formElementReducer = (elements = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_FORM_ELEMENT:
      return payload;

    case RETRIEVE_FORM_ELEMENTS:
      return payload;

    default:
      return elements;
  }
};

export default formElementReducer;
