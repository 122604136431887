import React from "react";

const EditIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_409_22561)">
        <path d="M12.6666 8.03064C12.298 8.03064 12 8.32935 12 8.69727V14.0306C12 14.3979 11.7013 14.6973 11.3334 14.6973H2C1.63196 14.6973 1.33337 14.3979 1.33337 14.0306V4.69727C1.33337 4.32996 1.63196 4.03064 2 4.03064H7.33337C7.70203 4.03064 8 3.73193 8 3.36401C8 2.99597 7.70203 2.69727 7.33337 2.69727H2C0.897339 2.69727 0 3.5946 0 4.69727V14.0306C0 15.1333 0.897339 16.0306 2 16.0306H11.3334C12.436 16.0306 13.3334 15.1333 13.3334 14.0306V8.69727C13.3334 8.32861 13.0353 8.03064 12.6666 8.03064Z" />
        <path d="M6.25075 7.42358C6.20412 7.47021 6.17275 7.52954 6.15944 7.59351L5.68813 9.95093C5.66616 10.0602 5.70082 10.1729 5.77944 10.2522C5.84279 10.3156 5.92812 10.3495 6.01552 10.3495C6.03676 10.3495 6.05886 10.3475 6.08083 10.3429L8.43752 9.87158C8.50283 9.85815 8.56215 9.8269 8.60817 9.78015L13.8828 4.50549L11.5261 2.14893L6.25075 7.42358Z" />
        <path d="M15.5117 0.518768C14.8618 -0.131256 13.8044 -0.131256 13.155 0.518768L12.2324 1.44138L14.5891 3.79807L15.5117 2.87534C15.8264 2.56137 15.9998 2.14267 15.9998 1.69736C15.9998 1.25204 15.8264 0.833344 15.5117 0.518768Z" />
      </g>
      <defs>
        <clipPath id="clip0_409_22561">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
