import { RETRIEVE_CONFIGS } from "actions/types";

const initialState = {
  data: [],
};

const configReducer = (configs = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CONFIGS:
      return payload;

    default:
      return configs;
  }
};

export default configReducer;
