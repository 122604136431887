import React, { useContext } from "react";

import clsx from "clsx";
import { Outlet } from "react-router-dom";

import { Sidebar } from "components/common";

import { AppContext } from "context/AppContext";

const PrimaryLayout = () => {
  const { showSidebar } = useContext(AppContext);

  return (
    <div className="bg-[#f3f4f6]">
      <Sidebar />
      <main
        className={clsx(
          showSidebar ? "lg:pl-60" : "lg:pl-20",
          "transition-all duration-300"
        )}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default PrimaryLayout;
