import React from "react";

const PencilIcon = ({ ...props }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.5342 10.2985L15.9586 8.79567L5.58014 18.6946V20.1974H7.15574L17.5342 10.2985ZM19.1098 8.79567L20.6854 7.29287L19.1098 5.79007L17.5342 7.29287L19.1098 8.79567ZM8.07837 22.323H3.35156V17.8135L18.322 3.53481C18.531 3.33556 18.8144 3.22363 19.1098 3.22363C19.4053 3.22363 19.6887 3.33556 19.8976 3.53481L23.0499 6.54147C23.2588 6.74077 23.3762 7.01105 23.3762 7.29287C23.3762 7.57469 23.2588 7.84497 23.0499 8.04427L8.07949 22.323H8.07837Z" />
    </svg>
  );
};

export default PencilIcon;
