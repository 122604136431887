import React from "react"

const RightTikIcon = ({ ...props }) => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.75014 8.27532L13.7931 0.231445L15.0313 1.4687L5.75014 10.7498L0.181641 5.18132L1.41889 3.94407L5.75014 8.27532Z" fill="#008CFF" />
    </svg>
  )
}

export default RightTikIcon