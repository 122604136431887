import React, { Fragment, useEffect } from "react";

import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { usePagination, useSortBy, useTable } from "react-table";

function ControlledTable({
  columns,
  data,
  placeholder,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  tabs,
  tabsPreview = true,
  pagination = true,
  search = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams(
    tabsPreview
      ? {
          filter: tabs[0].filter,
          page: 1,
          limit: 10,
          q: "",
        }
      : {
          page: 1,
          limit: 10,
          q: "",
        }
  );
  const filter = searchParams.get("filter");
  const pageIndex = searchParams.get("page");
  const limit = searchParams.get("limit");
  const q = searchParams.get("q");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData && fetchData();
  }, [fetchData]);

  return (
    <Fragment>
      {tabsPreview && (
        <div className="flex overflow-y-auto">
          <div className="mb-6 flex w-fit space-x-2 whitespace-nowrap rounded-full bg-white px-4 py-2.5">
            {tabs !== undefined &&
              tabs.map((tab, i) => {
                return (
                  <div key={i}>
                    <button
                      type="button"
                      className={clsx(
                        filter === tab.filter
                          ? "bg-azure-radiance text-white"
                          : "bg-alice-blue text-azure-radiance",
                        "rounded-full py-2.5 px-4 text-sm font-medium leading-5 transition-all duration-300 active:focus:scale-95"
                      )}
                      onClick={() =>
                        setSearchParams(
                          tabsPreview
                            ? {
                                filter: tab.filter,
                                page: 1,
                                limit,
                                q,
                              }
                            : {
                                page: 1,
                                limit,
                                q,
                              }
                        )
                      }
                    >
                      {tab.name}
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {search && (
        <div className="mb-8 flex justify-end">
          <div className="relative h-10 w-full xl:w-auto">
            <span className="material-icons-outlined absolute inset-y-0 left-3 my-auto h-5 w-5 select-none text-xl leading-none text-storm-gray">
              search
            </span>
            <input
              type="search"
              className="w-full rounded-full border-none bg-white pl-10 text-sm font-medium leading-6 placeholder:font-light placeholder:text-cadet-blue focus:ring-0 xl:w-[340px]"
              placeholder={placeholder}
              value={q || ""}
              onChange={(e) =>
                setSearchParams(
                  tabsPreview
                    ? {
                        filter,
                        page: pageIndex,
                        limit,
                        q: e.target.value,
                      }
                    : {
                        page: pageIndex,
                        limit,
                        q: e.target.value,
                      }
                )
              }
            />
          </div>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="w-full table-auto" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="select-none whitespace-nowrap border-b border-[#dddee0] bg-[#f9fafb] px-4 py-4 text-left text-sm font-semibold leading-4 text-[#111827]"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading &&
              page &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    className="bg-white text-[#6b7280]"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="max-w-sm overflow-hidden text-ellipsis whitespace-nowrap border-b border-[#e5e7eb] px-4 py-2.5 text-sm font-normal leading-4"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!loading && page.length === 0 && (
              <tr className="bg-white text-[#6b7280]">
                <td
                  className="max-w-sm overflow-hidden text-ellipsis whitespace-nowrap border-b border-[#e5e7eb] px-4 py-4 text-center text-sm font-normal leading-4"
                  colSpan={"9999"}
                >
                  No matching records found
                </td>
              </tr>
            )}
            {loading && (
              <tr className="bg-white text-[#6b7280]">
                <td
                  className="max-w-sm overflow-hidden text-ellipsis whitespace-nowrap border-b border-[#e5e7eb] px-4 py-4 text-center text-sm font-normal leading-4"
                  colSpan={"9999"}
                >
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {page.length !== 0 && pagination && (
        <div className="mt-4 flex items-center justify-between space-x-10 overflow-y-auto">
          <div className="flex space-x-3">
            <button
              type="button"
              className={clsx(
                parseInt(pageIndex) === 1
                  ? "cursor-not-allowed hover:shadow-none"
                  : "btn-animated hover:shadow-[0px_0px_15px_rgba(160,163,189,0.3)]",
                "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white transition-all ease-linear"
              )}
              onClick={() =>
                setSearchParams(
                  tabsPreview
                    ? { filter, page: 1, limit, q }
                    : { page: 1, limit, q }
                )
              }
              disabled={parseInt(pageIndex) === 1}
            >
              <span
                className={clsx(
                  parseInt(pageIndex) === 1
                    ? "text-cadet-blue"
                    : "text-independence",
                  "material-icons-outlined"
                )}
              >
                keyboard_double_arrow_left
              </span>
            </button>
            <button
              type="button"
              className={clsx(
                parseInt(pageIndex) === 1
                  ? "cursor-not-allowed hover:shadow-none"
                  : "btn-animated hover:shadow-[0px_0px_15px_rgba(160,163,189,0.3)]",
                "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white transition-all ease-linear"
              )}
              onClick={() => {
                setSearchParams(
                  tabsPreview
                    ? {
                        filter,
                        page: parseInt(pageIndex) - 1,
                        limit,
                        q,
                      }
                    : {
                        page: parseInt(pageIndex) - 1,
                        limit,
                        q,
                      }
                );
              }}
              disabled={parseInt(pageIndex) === 1}
            >
              <span
                className={clsx(
                  parseInt(pageIndex) === 1
                    ? "text-cadet-blue"
                    : "text-independence",
                  "material-icons-outlined"
                )}
              >
                chevron_left
              </span>
            </button>
            <button
              type="button"
              className={clsx(
                pageCount === parseInt(pageIndex)
                  ? "cursor-not-allowed hover:shadow-none"
                  : "btn-animated hover:shadow-[0px_0px_15px_rgba(160,163,189,0.3)]",
                "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white transition-all ease-linear"
              )}
              onClick={() =>
                setSearchParams(
                  tabsPreview
                    ? {
                        filter,
                        page: parseInt(pageIndex) + 1,
                        limit,
                        q,
                      }
                    : {
                        page: parseInt(pageIndex) + 1,
                        limit,
                        q,
                      }
                )
              }
              disabled={pageCount === parseInt(pageIndex)}
            >
              <span
                className={clsx(
                  pageCount === parseInt(pageIndex)
                    ? "text-cadet-blue"
                    : "text-independence",
                  "material-icons-outlined"
                )}
              >
                chevron_right
              </span>
            </button>
            <button
              type="button"
              className={clsx(
                pageCount === parseInt(pageIndex)
                  ? "cursor-not-allowed hover:shadow-none"
                  : "btn-animated hover:shadow-[0px_0px_15px_rgba(160,163,189,0.3)]",
                "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white transition-all ease-linear"
              )}
              onClick={() =>
                setSearchParams(
                  tabsPreview
                    ? { filter, page: pageCount, limit, q }
                    : { page: pageCount, limit, q }
                )
              }
              disabled={pageCount === parseInt(pageIndex)}
            >
              <span
                className={clsx(
                  pageCount === parseInt(pageIndex)
                    ? "text-cadet-blue"
                    : "text-independence",
                  "material-icons-outlined"
                )}
              >
                keyboard_double_arrow_right
              </span>
            </button>
          </div>
          <div className="flex items-center space-x-2 whitespace-nowrap text-sm text-storm-gray">
            <span>
              Page{" "}
              <strong>
                {pageIndex} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex}
                min={1}
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setSearchParams(
                    tabsPreview
                      ? { filter, page: page, limit, q }
                      : { page: page, limit, q }
                  );
                }}
                className="w-20 rounded border-none bg-white text-sm text-independence focus:ring-0"
              />
            </span>
          </div>
          <div className="flex items-center space-x-4 whitespace-nowrap text-sm">
            <div className="hidden text-storm-gray sm:block">Show on page</div>
            <select
              className="rounded border-none bg-white text-sm text-independence focus:ring-0"
              value={limit}
              onChange={(e) =>
                setSearchParams(
                  tabsPreview
                    ? {
                        filter,
                        page: 1,
                        limit: Number(e.target.value),
                        q,
                      }
                    : {
                        page: 1,
                        limit: Number(e.target.value),
                        q,
                      }
                )
              }
            >
              {[10, 20, 30, 40, 50].map((pageSize, i) => (
                <option key={i} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ControlledTable;
