import React from "react";

const FacebookIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_409_21431)">
        <rect width="46" height="46" fill="#DFE3E5" />
        <path
          d="M43.4609 8.12923e-08H2.53703C1.13568 0.000701986 -0.000350871 1.13744 8.12923e-08 2.53914V43.463C0.000701986 44.8643 1.13744 46.0003 2.53914 46H43.4609C44.8629 46.0003 45.9996 44.864 46 43.4619C46 43.4616 46 43.4612 46 43.4609V2.53703C45.9993 1.13568 44.8626 -0.000350871 43.4609 8.12923e-08Z"
          fill="#DFE3E5"
        />
        <path
          d="M31.7598 45.9999V28.2109H37.7568L38.6553 21.248H31.7598V16.8133C31.7598 14.802 32.3181 13.4316 35.2023 13.4316H38.8574V7.22076C38.2215 7.13618 36.0396 6.94702 33.5008 6.94702C28.2001 6.94702 24.5723 10.1814 24.5723 16.1237V21.248H18.5977V28.2109H24.5723V45.9999H31.7598Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_21431">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
