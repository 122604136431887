import { RETRIEVE_USER, VERIFY_USER } from "actions/types";

const initialState = {
  data: [],
};

const userReducer = (users = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VERIFY_USER:
      return payload;

    case RETRIEVE_USER:
      return payload;

    default:
      return users;
  }
};

export default userReducer;
