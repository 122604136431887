import { RETRIEVE_MARKETING_LIST } from "actions/types";

const initialState = {
  data: [],
};

const cityMarketingReducer = (cityMarketingList = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_MARKETING_LIST:
      return payload;

    default:
      return cityMarketingList;
  }
};

export default cityMarketingReducer;
