import { RETRIEVE_COUNCIL } from "actions/types";

const initialState = {
  data: [],
};

const councilReducer = (councils = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_COUNCIL:
      return payload;

    default:
      return councils;
  }
};

export default councilReducer;
