import React from "react";

const GoogleFormsIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.5156 4.68744H15.2739L10.9297 0.343262V4.1015C10.9297 4.42478 11.1923 4.68744 11.5156 4.68744Z" />
      <path d="M2.14062 20H13.8594C14.8287 20 15.6172 19.2115 15.6172 18.2422V5.85938H11.5156C10.5463 5.85938 9.75781 5.07086 9.75781 4.10156V0H2.14062C1.17133 0 0.382812 0.788516 0.382812 1.75781V18.2422C0.382812 19.2115 1.17133 20 2.14062 20ZM5.65625 9.41406H11.5156C11.8395 9.41406 12.1016 9.67613 12.1016 10C12.1016 10.3239 11.8395 10.5859 11.5156 10.5859H5.65625C5.33238 10.5859 5.07031 10.3239 5.07031 10C5.07031 9.67613 5.33238 9.41406 5.65625 9.41406ZM5.65625 11.7578H11.5156C11.8395 11.7578 12.1016 12.0199 12.1016 12.3438C12.1016 12.6676 11.8395 12.9297 11.5156 12.9297H5.65625C5.33238 12.9297 5.07031 12.6676 5.07031 12.3438C5.07031 12.0199 5.33238 11.7578 5.65625 11.7578ZM5.65625 14.1016H11.5156C11.8395 14.1016 12.1016 14.3636 12.1016 14.6875C12.1016 15.0114 11.8395 15.2734 11.5156 15.2734H5.65625C5.33238 15.2734 5.07031 15.0114 5.07031 14.6875C5.07031 14.3636 5.33238 14.1016 5.65625 14.1016ZM3.3125 9.41406C3.63609 9.41406 3.89844 9.67641 3.89844 10C3.89844 10.3236 3.63609 10.5859 3.3125 10.5859C2.98891 10.5859 2.72656 10.3236 2.72656 10C2.72656 9.67641 2.98891 9.41406 3.3125 9.41406ZM3.3125 11.7578C3.63609 11.7578 3.89844 12.0202 3.89844 12.3438C3.89844 12.6673 3.63609 12.9297 3.3125 12.9297C2.98891 12.9297 2.72656 12.6673 2.72656 12.3438C2.72656 12.0202 2.98891 11.7578 3.3125 11.7578ZM3.3125 14.1016C3.63609 14.1016 3.89844 14.3639 3.89844 14.6875C3.89844 15.0111 3.63609 15.2734 3.3125 15.2734C2.98891 15.2734 2.72656 15.0111 2.72656 14.6875C2.72656 14.3639 2.98891 14.1016 3.3125 14.1016Z" />
    </svg>
  );
};

export default GoogleFormsIcon;
