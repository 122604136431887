import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

import { XIcon } from "components/icons";

const Modal = ({
  show,
  onClose,
  onCancel,
  title,
  children,
  showCancel = true,
  maxWidth = "36rem",
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-mirage bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{
                  maxWidth: maxWidth,
                }}
                className={clsx(
                  "modal-height w-full transform overflow-hidden overflow-y-auto rounded-2xl bg-white p-8 align-middle shadow-xl transition-all"
                )}
              >
                <div
                  className={clsx(
                    showCancel
                      ? "flex items-center justify-between"
                      : "text-center",
                    "mb-6"
                  )}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-normal text-independence"
                  >
                    {title}
                  </Dialog.Title>
                  {showCancel && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center outline-none"
                      onClick={onCancel}
                    >
                      <XIcon className="h-3 w-3 text-storm-gray" />
                    </button>
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
