import React from "react";

const HelpIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 0C4.48578 0 0 4.48578 0 10C0 15.5142 4.48578 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48578 15.5142 0 10 0ZM10 15.8333C9.53995 15.8333 9.16672 15.4601 9.16672 15C9.16672 14.5399 9.53995 14.1667 10 14.1667C10.4601 14.1667 10.8333 14.5399 10.8333 15C10.8333 15.4601 10.4601 15.8333 10 15.8333ZM11.3191 10.535C11.0242 10.6708 10.8333 10.9683 10.8333 11.2926V11.6667C10.8333 12.1266 10.4608 12.5 10 12.5C9.53918 12.5 9.16672 12.1266 9.16672 11.6667V11.2926C9.16672 10.32 9.73831 9.42841 10.6209 9.02084C11.47 8.63007 12.0833 7.59247 12.0833 7.08328C12.0833 5.93506 11.1491 5 10 5C8.85086 5 7.91672 5.93506 7.91672 7.08328C7.91672 7.54333 7.5441 7.91672 7.08328 7.91672C6.62247 7.91672 6.25 7.54333 6.25 7.08328C6.25 5.01587 7.93243 3.33328 10 3.33328C12.0676 3.33328 13.75 5.01587 13.75 7.08328C13.75 8.20923 12.7733 9.8642 11.3191 10.535Z" />
    </svg>
  );
};

export default HelpIcon;
