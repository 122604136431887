import { useContext, useEffect } from "react";

import { AppContext } from "context/AppContext";

import { ROLE } from "utils/roles";

const useRole = (roles) => {
  const { setRole } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("cvx-u"));

  const isPark = token?.departmentUser[0]?.zonalDepartment?.isParkEvent;

  const parkAdmin = isPark && token?.role?.name === ROLE.DEPARTMENT_ADMIN;
  const parkUser = isPark && token?.role?.name === ROLE.DEPARTMENT_USER;

  useEffect(() => {
    setRole(
      parkAdmin
        ? ROLE.PARK_ADMIN
        : parkUser
        ? ROLE.PARK_USER
        : token?.role?.name
    );
  }, [setRole, parkAdmin, parkUser, token?.role?.name]);

  const role = roles.includes(
    parkAdmin ? ROLE.PARK_ADMIN : parkUser ? ROLE.PARK_USER : token?.role?.name
  );

  return role;
};

export default useRole;
