import React from "react";

const ChevronLeftIcon = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.254772 7.37568L7.37498 0.255608C7.53966 0.090796 7.75949 0 7.9939 0C8.2283 0 8.44814 0.090796 8.61282 0.255608L9.13717 0.779831C9.47837 1.12142 9.47837 1.6766 9.13717 2.01767L3.15816 7.99668L9.14381 13.9823C9.30849 14.1471 9.39941 14.3668 9.39941 14.6011C9.39941 14.8357 9.30849 15.0554 9.14381 15.2203L8.61945 15.7444C8.45464 15.9092 8.23494 16 8.00053 16C7.76613 16 7.54629 15.9092 7.38161 15.7444L0.254772 8.61782C0.0896997 8.45248 -0.000966072 8.23174 -0.000445366 7.99707C-0.000966072 7.7615 0.0896997 7.54088 0.254772 7.37568Z" />
    </svg>
  );
};

export default ChevronLeftIcon;
