import React from "react";

const CivixSecondaryIcon = ({ ...props }) => {
  return (
    <svg
      width="94"
      height="32"
      viewBox="0 0 94 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 19.1697C0 11.5456 5.8615 6.33594 13.2347 6.33594C18.983 6.33594 22.1949 9.31339 23.9739 12.3987L19.3601 14.631C18.3009 12.6213 16.0351 11.023 13.2347 11.023C8.8471 11.023 5.52215 14.4826 5.52215 19.1697C5.52215 23.8567 8.8471 27.313 13.2347 27.313C16.0317 27.313 18.3009 25.7517 19.3601 23.705L23.9739 25.9001C22.1572 28.9888 18.983 32 13.2347 32C5.8615 32 0 26.7937 0 19.1697Z"
        fill="white"
      />
      <path
        d="M27.1836 6.74365H32.5549V31.5512H27.1836V6.74365Z"
        fill="white"
      />
      <path
        d="M59.7422 0H66.087L43.0661 31.5549H37.5508L59.7422 0Z"
        fill="url(#paint0_linear_409_27367)"
      />
      <path d="M66.1531 6.74414V31.5517H60.7852V14.1051" fill="white" />
      <path
        d="M37.5488 6.74414H42.9202V31.5517H37.5488V6.74414Z"
        fill="white"
      />
      <path
        d="M81.2547 22.4983L74.9031 31.5722H68.5137L77.6624 18.8532L69.0793 6.76465H75.4686L81.2513 15.2452L86.962 6.76465H93.4268L84.847 18.8161L93.9958 31.5722H87.5687L81.2547 22.4983Z"
        fill="#008CFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_409_27367"
          x1="63.477"
          y1="-0.784555"
          x2="40.107"
          y2="32.8708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.53125" stopColor="#DAE3EC" />
          <stop offset="1" stopColor="#C7D1E6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CivixSecondaryIcon;
