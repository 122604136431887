import React from "react";

const SearchIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.74641 3.24719C7.88912 -0.746212 14.0543 -0.587045 17.8885 3.24719C21.555 6.91369 21.7792 12.719 18.561 16.647L24.2525 22.3391L22.8383 23.7533L17.1462 18.0618C13.2182 21.28 7.4129 21.0558 3.74641 17.3893C-0.0878289 13.5551 -0.268118 7.11704 3.74641 3.24719ZM16.4743 4.66141C13.3501 1.53721 8.28482 1.53721 5.16062 4.66141C2.03643 7.7856 2.03643 12.8509 5.16062 15.9751C8.28482 19.0993 13.3501 19.0993 16.4743 15.9751C19.5985 12.8509 19.5985 7.7856 16.4743 4.66141Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchIcon;
