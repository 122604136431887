import UserService from "services/UserService";

import {
  CHANGE_PASSWORD,
  DELETE_USER,
  FORGOT_PASSWORD,
  RENEWTOKEN,
  RESET_PASSWORD,
  RETRIEVE_USER,
  RETRIEVE_USER_BY_ID,
  SIGNIN,
  SIGNUP,
  STORE_USER_TOKEN,
  UPDATE_USER,
  USER_SUBSCRIPTION_CHECK,
  VERIFY_USER,
} from "./types";

export const signIn = (data) => async (dispatch) => {
  try {
    const res = await UserService.signIn(data);

    dispatch({
      type: SIGNIN,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const renewTokens = (data) => async (dispatch) => {
  try {
    const res = await UserService.renewTokens(data);

    dispatch({
      type: RENEWTOKEN,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signUp = (data) => async (dispatch) => {
  try {
    const res = await UserService.signUp(data);

    dispatch({
      type: SIGNUP,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  const res = await UserService.forgotPassword(data);

  try {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = (data) => async (dispatch) => {
  const res = await UserService.resetPassword(data);

  try {
    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyUser = (id) => async (dispatch) => {
  try {
    const res = await UserService.verifyUser(id);

    dispatch({
      type: VERIFY_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveUserById = (data, params) => async (dispatch) => {
  try {
    const res = await UserService.get(data, params);
    dispatch({
      type: RETRIEVE_USER_BY_ID,
      payload: res.data,
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveUser = (params) => async (dispatch) => {
  try {
    const res = await UserService.getAll(params);

    dispatch({
      type: RETRIEVE_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.update(id, data);

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await UserService.remove(id);

    dispatch({
      type: DELETE_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const storeUserToken = (data) => async (dispatch) => {
  try {
    const res = await UserService.createToken(data);

    dispatch({
      type: STORE_USER_TOKEN,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userSubscriptionCheck = () => async (dispatch) => {
  try {
    const res = await UserService.userSubscriptionCheck();

    dispatch({
      type: USER_SUBSCRIPTION_CHECK,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    const res = await UserService.changePassword(data);

    dispatch({
      type: CHANGE_PASSWORD,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
