import React from "react";

const ChevronRightIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.4444 7.37568L5.32424 0.255608C5.15956 0.090796 4.93973 0 4.70532 0C4.47092 0 4.25108 0.090796 4.0864 0.255608L3.56205 0.779831C3.22085 1.12142 3.22085 1.6766 3.56205 2.01767L9.54106 7.99668L3.55541 13.9823C3.39073 14.1471 3.2998 14.3668 3.2998 14.6011C3.2998 14.8357 3.39073 15.0554 3.55541 15.2203L4.07977 15.7444C4.24458 15.9092 4.46428 16 4.69869 16C4.93309 16 5.15293 15.9092 5.31761 15.7444L12.4444 8.61782C12.6095 8.45248 12.7002 8.23174 12.6997 7.99707C12.7002 7.7615 12.6095 7.54088 12.4444 7.37568Z" />
    </svg>
  );
};

export default ChevronRightIcon;
