import React from "react";

const StarIcon = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.5 0L8.28181 4.83741L11.5548 1.1906L9.59721 5.68276L14.3222 4.38439L10.2468 7.10508L14.9237 8.56736L10.0242 8.65278L13.1681 12.4115L9.00028 9.83448L9.61299 14.6962L7.5 10.275L5.38701 14.6962L5.99972 9.83448L1.83188 12.4115L4.97577 8.65278L0.0763392 8.56736L4.75325 7.10508L0.67776 4.38439L5.40279 5.68276L3.44519 1.1906L6.71819 4.83741L7.5 0Z" />
    </svg>
  );
};

export default StarIcon;
