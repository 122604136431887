export default function authHeader() {
  const token = JSON.parse(localStorage.getItem("cvxu"));

  if (token && token.accessToken) {
    return {
      "Content-type": "application/json",
      Authorization: `Bearer ${token.accessToken}`,
    };
  } else {
    return {};
  }
}
