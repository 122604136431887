import { APPROVAL_LOGS } from "actions/types";

const initialState = {
  data: [],
};

const applicationLogReducer = (applicationLogs = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APPROVAL_LOGS:
      return payload;

    default:
      return applicationLogs;
  }
};

export default applicationLogReducer;
