import ContactUsService from "services/ContactUsService";

import { CONTACT_US } from "./types";

export const sendMail = (data) => async (dispatch) => {
  try {
    const res = await ContactUsService.sendMail(data);

    dispatch({
      type: CONTACT_US,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
