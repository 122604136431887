import { RETRIEVE_STATE, RETRIEVE_STATES } from "actions/types";

const initialState = {
  data: [],
};

const stateReducer = (states = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_STATE:
      return payload;

    case RETRIEVE_STATES:
      return payload;

    default:
      return states;
  }
};

export default stateReducer;
