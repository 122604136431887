import React from "react";

const HamburgerIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.5 3.5H1.5C0.65 3.5 0 2.85 0 2C0 1.15 0.65 0.5 1.5 0.5H22.5C23.35 0.5 24 1.15 24 2C24 2.85 23.35 3.5 22.5 3.5Z" />
      <path d="M22.5 10.5H1.5C0.65 10.5 0 9.85 0 9C0 8.15 0.65 7.5 1.5 7.5H22.5C23.35 7.5 24 8.15 24 9C24 9.85 23.35 10.5 22.5 10.5Z" />
      <path d="M22.5 17.5H1.5C0.65 17.5 0 16.85 0 16C0 15.15 0.65 14.5 1.5 14.5H22.5C23.35 14.5 24 15.15 24 16C24 16.85 23.35 17.5 22.5 17.5Z" />
    </svg>
  );
};

export default HamburgerIcon;
