import { RETRIEVE_COUNTRY } from "actions/types";

const initialState = {
  data: [],
};

const countryReducer = (countries = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_COUNTRY:
      return payload;

    default:
      return countries;
  }
};

export default countryReducer;
