import React from "react";

const DashboardIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.4375 10.625H11.5625C11.0447 10.625 10.625 11.0447 10.625 11.5625V18.4375C10.625 18.9553 11.0447 19.375 11.5625 19.375H18.4375C18.9553 19.375 19.375 18.9553 19.375 18.4375V11.5625C19.375 11.0447 18.9553 10.625 18.4375 10.625Z" />
      <path d="M8.4375 10.625H1.5625C1.04473 10.625 0.625 11.0447 0.625 11.5625V18.4375C0.625 18.9553 1.04473 19.375 1.5625 19.375H8.4375C8.95527 19.375 9.375 18.9553 9.375 18.4375V11.5625C9.375 11.0447 8.95527 10.625 8.4375 10.625Z" />
      <path d="M18.4375 0.625H11.5625C11.0447 0.625 10.625 1.04473 10.625 1.5625V8.4375C10.625 8.95527 11.0447 9.375 11.5625 9.375H18.4375C18.9553 9.375 19.375 8.95527 19.375 8.4375V1.5625C19.375 1.04473 18.9553 0.625 18.4375 0.625Z" />
      <path d="M8.4375 0.625H1.5625C1.04473 0.625 0.625 1.04473 0.625 1.5625V8.4375C0.625 8.95527 1.04473 9.375 1.5625 9.375H8.4375C8.95527 9.375 9.375 8.95527 9.375 8.4375V1.5625C9.375 1.04473 8.95527 0.625 8.4375 0.625Z" />
    </svg>
  );
};

export default DashboardIcon;
