import React from "react"

const CircleAcceptIcon = ({ ...props }) => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="27" cy="27" r="27" fill="#008CFF" />
      <path d="M25.0007 31.172L34.1927 21.979L35.6077 23.393L25.0007 34L18.6367 27.636L20.0507 26.222L25.0007 31.172Z" fill="white" />
    </svg>
  )
}

export default CircleAcceptIcon