import React from "react";

const AnalyticsIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.625 10.25H0.875C0.529822 10.25 0.25 10.5298 0.25 10.875V17.125C0.25 17.4702 0.529822 17.75 0.875 17.75H4.625C4.97018 17.75 5.25 17.4702 5.25 17.125V10.875C5.25 10.5298 4.97018 10.25 4.625 10.25Z" />
      <path d="M17.125 5.25H13.375C13.0298 5.25 12.75 5.52982 12.75 5.875V17.125C12.75 17.4702 13.0298 17.75 13.375 17.75H17.125C17.4702 17.75 17.75 17.4702 17.75 17.125V5.875C17.75 5.52982 17.4702 5.25 17.125 5.25Z" />
      <path d="M10.875 0.25H7.125C6.77982 0.25 6.5 0.529822 6.5 0.875V17.125C6.5 17.4702 6.77982 17.75 7.125 17.75H10.875C11.2202 17.75 11.5 17.4702 11.5 17.125V0.875C11.5 0.529822 11.2202 0.25 10.875 0.25Z" />
    </svg>
  );
};

export default AnalyticsIcon;
