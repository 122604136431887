import React from "react";

const PdfIcon = ({ ...props }) => {
  return (
    <svg
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71143 0H16.0787L25.7129 10.042V29.5737C25.7129 32.0205 23.7334 34 21.2951 34H4.71143C2.26466 34 0.285156 32.0205 0.285156 29.5737V4.42627C0.285113 1.9795 2.26462 0 4.71143 0V0Z"
        fill="#E5252A"
      />
      <path
        opacity="0.302"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0703 0V9.96553H25.713L16.0703 0Z"
        fill="white"
      />
      <path
        d="M5.20312 25.3681V19.1577H7.84532C8.49949 19.1577 9.01775 19.3361 9.40855 19.7014C9.79935 20.0583 9.99477 20.5425 9.99477 21.1457C9.99477 21.7489 9.79935 22.2332 9.40855 22.59C9.01775 22.9553 8.49949 23.1337 7.84532 23.1337H6.79183V25.3681H5.20312ZM6.79183 21.7829H7.66691C7.90477 21.7829 8.09168 21.7319 8.21914 21.613C8.34656 21.5026 8.41456 21.3496 8.41456 21.1458C8.41456 20.9419 8.3466 20.7889 8.21914 20.6785C8.09173 20.5595 7.90481 20.5086 7.66691 20.5086H6.79183V21.7829ZM10.6489 25.3681V19.1577H12.8493C13.2826 19.1577 13.6904 19.2172 14.0727 19.3446C14.455 19.472 14.8033 19.6505 15.1092 19.8969C15.415 20.1347 15.6614 20.4576 15.8398 20.8654C16.0097 21.2732 16.1032 21.7404 16.1032 22.2672C16.1032 22.7854 16.0098 23.2527 15.8398 23.6604C15.6614 24.0682 15.415 24.3911 15.1092 24.6289C14.8033 24.8753 14.455 25.0537 14.0727 25.1812C13.6904 25.3086 13.2826 25.3681 12.8493 25.3681H10.6489ZM12.2036 24.0173H12.6624C12.9088 24.0173 13.1382 23.9918 13.3505 23.9323C13.5544 23.8729 13.7499 23.7794 13.9368 23.652C14.1152 23.5246 14.2596 23.3461 14.3615 23.1082C14.4635 22.8704 14.5145 22.59 14.5145 22.2672C14.5145 21.9358 14.4635 21.6555 14.3615 21.4176C14.2596 21.1797 14.1152 21.0013 13.9368 20.8739C13.7499 20.7465 13.5545 20.653 13.3505 20.5935C13.1382 20.5341 12.9088 20.5086 12.6624 20.5086H12.2036V24.0173ZM16.9018 25.3681V19.1577H21.3196V20.5086H18.4905V21.5025H20.7504V22.8449H18.4905V25.3681H16.9018Z"
        fill="white"
      />
    </svg>
  );
};

export default PdfIcon;
