import React, { Fragment } from "react";

import { Outlet } from "react-router-dom";

import { Footer, Navbar } from "components/common";

const Layout = () => {
  return (
    <Fragment>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
