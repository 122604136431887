import React from "react";

const EmailIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5512 9.99609L13.5412 12.0129C12.726 12.8309 11.2919 12.8485 10.4591 12.0129L8.44899 9.99609L1.22949 17.239C1.49823 17.3633 1.79452 17.4375 2.10952 17.4375H21.8908C22.2058 17.4375 22.502 17.3634 22.7706 17.2391L15.5512 9.99609Z"
        fill="#0C7DFB"
      />
      <path
        d="M21.8907 0.5625H2.10943C1.79443 0.5625 1.49813 0.636656 1.22949 0.760922L8.94404 8.50106C8.94456 8.50158 8.94517 8.50167 8.94568 8.50219C8.9462 8.5027 8.94629 8.50341 8.94629 8.50341L11.4547 11.0201C11.7212 11.2866 12.2791 11.2866 12.5455 11.0201L15.0534 8.50383C15.0534 8.50383 15.054 8.5027 15.0545 8.50219C15.0545 8.50219 15.0557 8.50158 15.0562 8.50106L22.7705 0.760875C22.5019 0.636563 22.2057 0.5625 21.8907 0.5625Z"
        fill="#0C7DFB"
      />
      <path
        d="M0.224344 1.74438C0.0853125 2.02554 0 2.33763 0 2.67195V15.3282C0 15.6625 0.0852187 15.9746 0.224297 16.2558L7.45631 9.0003L0.224344 1.74438Z"
        fill="#0C7DFB"
      />
      <path
        d="M23.7759 1.74414L16.5439 9.00016L23.7759 16.2557C23.9149 15.9746 24.0002 15.6625 24.0002 15.3281V2.6718C24.0002 2.33739 23.9149 2.0253 23.7759 1.74414Z"
        fill="#0C7DFB"
      />
    </svg>
  );
};

export default EmailIcon;
