import { RETRIEVE_FORM_GROUP, RETRIEVE_FORM_GROUPS } from "actions/types";

const initialState = {
  data: [],
};

const formGroupReducer = (groups = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_FORM_GROUP:
      return payload;

    case RETRIEVE_FORM_GROUPS:
      return payload;

    default:
      return groups;
  }
};

export default formGroupReducer;
