import React from "react"

const CloseIcon = ({ ...props }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3007 5.77777C17.9107 5.38314 17.2807 5.38314 16.8907 5.77777L12.0007 10.7157L7.1107 5.76765C6.7207 5.37302 6.0907 5.37302 5.7007 5.76765C5.3107 6.16228 5.3107 6.79977 5.7007 7.1944L10.5907 12.1425L5.7007 17.0905C5.3107 17.4852 5.3107 18.1227 5.7007 18.5173C6.0907 18.9119 6.7207 18.9119 7.1107 18.5173L12.0007 13.5692L16.8907 18.5173C17.2807 18.9119 17.9107 18.9119 18.3007 18.5173C18.6907 18.1227 18.6907 17.4852 18.3007 17.0905L13.4107 12.1425L18.3007 7.1944C18.6807 6.80989 18.6807 6.16228 18.3007 5.77777Z" fill="#F33A2E" />
    </svg>

  )
}

export default CloseIcon