import { CHANGE_PASSWORD, LOGOUT } from "actions/types";

const user = JSON.parse(localStorage.getItem("cvxu"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default authReducer;
