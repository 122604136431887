import { PARK_DASHBOARD_COUNT } from "actions/types";

const initialState = {
  data: [],
};

const dashboardReducer = (dashboard = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PARK_DASHBOARD_COUNT:
      return payload;

    default:
      return dashboard;
  }
};

export default dashboardReducer;
