import React from "react";

function ApproveIcon({ ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0_124_15236)">
        <path
          d="M10.0007 15.172L19.1927 5.979L20.6077 7.393L10.0007 18L3.63672 11.636L5.05072 10.222L10.0007 15.172Z"
          fill="currentColor"
        />
        <path
          d="M10.0007 21.172L19.1927 11.979L20.6077 13.393L10.0007 24L3.63672 17.636L5.05072 16.222L10.0007 21.172Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default ApproveIcon;
