import { RETRIEVE_CITIES, RETRIEVE_CITY } from "actions/types";

const initialState = {
  data: [],
};

const cityReducer = (cities = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CITY:
      return payload;

    case RETRIEVE_CITIES:
      return payload;

    default:
      return cities;
  }
};

export default cityReducer;
