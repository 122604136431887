import React from "react";

const YouTubeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V44C0 45.1046 0.89543 46 2 46H44C45.1046 46 46 45.1046 46 44V2C46 0.895431 45.1046 0 44 0H2ZM33.2569 14.1251C34.3813 14.4279 35.2679 15.3144 35.5704 16.4389C36.1328 18.4929 36.1112 22.7742 36.1112 22.7742C36.1112 22.7742 36.1112 27.0338 35.5707 29.0879C35.2679 30.2123 34.3815 31.0988 33.2569 31.4014C31.2027 31.9422 22.9863 31.9422 22.9863 31.9422C22.9863 31.9422 14.7912 31.9422 12.7156 31.38C11.591 31.0772 10.7047 30.1907 10.4019 29.0663C9.86133 27.0338 9.86133 22.7526 9.86133 22.7526C9.86133 22.7526 9.86133 18.4929 10.4019 16.4389C10.7044 15.3145 11.6127 14.4063 12.7154 14.1038C14.7696 13.563 22.986 13.563 22.986 13.563C22.986 13.563 31.2027 13.563 33.2569 14.1251ZM27.2026 22.7526L20.37 18.8174V26.6879L27.2026 22.7526Z"
        fill="#DFE3E5"
      />
    </svg>
  );
};

export default YouTubeIcon;
