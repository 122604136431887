import authHeader from "auth-header";
import http from "http-common";

const getAll = (stateId) => {
  return http.get(
    `/cities?include=country,state&filter[state.id]=${stateId}&sort=updatedAt&order=desc&search&page=1&limit=${1000}`,
    { headers: authHeader() }
  );
};

const getCities = (params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };

  return http.get("/cities", config);
};

const get = (id) => {
  return http.get(`/city/${id}?include=country,state`, {
    headers: authHeader(),
  });
};

const create = (data) => {
  return http.post("/city", data, { headers: authHeader() });
};

const update = (id, data) => {
  return http.patch(`/city/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/city/${id}`, { headers: authHeader() });
};

const CityService = {
  getAll,
  getCities,
  get,
  create,
  update,
  remove,
};

export default CityService;
