import { CREATE_STRIPE_CUSTOMER, GET_SUBSCRIPTION_LIST } from "actions/types";

const initialState = {
  data: [],
};

const subscriptionReducer = (subscription = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBSCRIPTION_LIST:
      return payload;

    default:
      return subscription;
  }
};

export default subscriptionReducer;
