import { GET_ALL_PARK_LOCATION, GET_ONE_PARK_LOCATION } from "actions/types";

const initialState = {
  data: [],
};

const parkLocationReducer = (parkLocations = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PARK_LOCATION:
      return payload;

    case GET_ONE_PARK_LOCATION:
      return payload;

    default:
      return parkLocations;
  }
};

export default parkLocationReducer;
